import "./Members.scss";
import React, { useEffect, useRef, useState } from "react";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { PasswordKeyIcon, PlusIcon } from "../../Assets/Assets";
import CommonModal from "../../Common/CommonModal/CommonModal";
import { Spin, Tabs } from "antd";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { shareItem } from "../../store/slices/shareSlice";
import useLogout from "../../CustomHooks/useLogout";
import { fetchContainers } from "../../store/slices/containerSlice";
import { HTTP_STATUS_CODE, INFINITE_SCROLLBAR } from "../../constants";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import MembersTable from "./MembersTable";
import { resetBreadcrumb } from "../../store/slices/breadcrumbSlice";
import useDebounce from "../../CustomHooks/useDebounce";
import { validateAddress } from "../../Utils/helpers";
import { LoadingOutlined } from "@ant-design/icons";
import { startUserGuide } from "../../Common/UserGuide/UserGuide";
import {
  fetchUserGuide,
  setIsMemberUserGuide,
} from "../../store/slices/userGuideSlice";

function Members() {
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const [isBtnDisable, setDisable] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [access, setAccess] = useState("read");
  const [wltErr, setWltErr] = useState("");

  // const [visibility, setVisibility] = useState("");
  const [selectedContainerId, setSelectedContainerId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { containers } = useSelector((state) => state.containers);
  const { csrfToken, isMemberGuide } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const { isMemberGuideStore } = useSelector((state) => state.userGuide);

  const { sharedItems } = useSelector((state) => state?.shareing);

  let page = useRef(INFINITE_SCROLLBAR.DEFAULT_PAGE);
  let limit = useRef(INFINITE_SCROLLBAR.DEFAULT_LIMIT);

  useEffect(() => {
    dispatch(resetBreadcrumb());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          fetchContainers({
            handleLogOut,
            searchTerm,
            page: page.current,
            limit: limit.current,
            prevData: containers,
          }),
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch containers:", error);
      }
    };

    fetchData();
  }, [searchTerm, page, limit]);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!walletAddress || !selectedContainerId || !access || wltErr) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [walletAddress, selectedContainerId, access, wltErr]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setWalletAddress("");
    setSelectedContainerId(null);
    setIsModalVisible(false);
    setAccess("read");
    setWltErr("");
  };

  const handleSearchChange = (e) => {
    if (e && e.target) {
      setSearchTerm(e.target.value);
    } else {
      console.error("Event or target is undefined");
    }
  };

  const tabItems = [
    {
      key: "1",
      label: "Shared with Members",
      children: (
        <MembersTable
          searchTerm={debouncedSearchTerm}
          isMemberGuide={isMemberGuide}
          isMemberGuideStore={isMemberGuideStore}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: "Received from Members",
    //   children: <ComingSoon />,
    //   // children: <ReceivedFromMembersTable />,
    // },
  ];

  const options = [];
  containers.map((container) => {
    options.push({
      value: container.id,
      label: container.name,
    });
  });

  const handleChange = (field, value) => {
    switch (field) {
      case "walletAddress": {
        setWalletAddress(value.trim());
        const err = validateAddress(value, auth.walletAddress);
        setWltErr(err);
        break;
      }
      case "access":
        setAccess(value);
        break;
      case "visibility":
        break;
      case "selectedContainerId":
        setSelectedContainerId(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    {
      !isMemberGuide && sharedItems.length == 0 && !isMemberGuideStore
        ? startUserGuide("addMember")
        : null;
    }
  }, [isMemberGuide, isMemberGuideStore]);

  const OperationsSlot = {
    right: (
      <>
        <InputCustom
          customClass="commonSearchInput"
          searchInputs
          placeholder="Search Member"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className="team_head_right">
          <ButtonCustom
            regularBtn
            onClick={showModal}
            label="Add Member"
            id="create-member-button"
          >
            <PlusIcon />
          </ButtonCustom>
        </div>
      </>
    ),
  };

  const handleShare = async () => {
    setDisable(true);
    setLoading(true);
    try {
      const res = await dispatch(
        shareItem({
          identifier: walletAddress,
          shareable_id: selectedContainerId,
          shareable_type: "container",
          access_level: access,
          access_type: "private",
          handleLogOut,
          csrfToken,
        }),
      );
      if (res?.payload?.code === HTTP_STATUS_CODE.OK) {
        if (sharedItems.length == 0 && !isMemberGuide) {
          await dispatch(
            fetchUserGuide({
              payload: "is_member_guide",
              handleLogOut,
              csrfToken,
            }),
          );
        }
        dispatch(setIsMemberUserGuide(true));

        toast.success(res?.payload?.message || "Member added successfully");

        resetForm();
      } else {
        resetForm();
      }
      closeModal();
    } catch (error) {
      toast.error("An unexpected error occurred");
      resetForm();
    }
  };

  const resetForm = () => {
    setLoading(false);
    setWalletAddress("");
    setIsModalVisible(false);
    setAccess("");
    setDisable(false);
    setSelectedContainerId("");
    setAccess("read");
  };

  return (
    <div className="team">
      <Tabs
        className="commonTab"
        defaultActiveKey="1"
        items={tabItems}
        tabBarExtraContent={OperationsSlot}
      />
      <CommonModal
        visible={isModalVisible}
        title="Add Member"
        className="addMemberModal"
        // BtnText="Continue"
        BtnText={
          loading ? <Spin indicator={<LoadingOutlined spin />} /> : "Continue"
        }
        onClick={() => handleShare()}
        customBtutton
        btnDisabled={isBtnDisable}
        icon={<PasswordKeyIcon />}
        content={
          <>
            {/* <div className="share-container_top">
              {" "}
              <CustomSelect
                regularInput
                label
                labelText="Access"
                required
                defaultValue="Public"
                value={visibility}
                options={[
                  { value: "public", label: "Public" },
                  { value: "private", label: "Private" },
                ]}
                onChange={(value) => handleChange("visibility", value)}
              />
            </div> 
            */}
            <InputCustom
              regularInput
              placeholder="Enter Here"
              label
              required
              error={wltErr}
              labletext="Wallet Address"
              value={walletAddress}
              onChange={(e) => handleChange("walletAddress", e?.target?.value)}
            />

            <CustomSelect
              regularInput
              label
              labelText="Choose Access Level"
              required
              defaultValue={access}
              value={access}
              onChange={(value) => handleChange("access", value)}
              description={
                access === "read"
                  ? "The user can only read the files in the container."
                  : access === "manage"
                    ? "User can fully manage the data in your container.They can view, upload and delete files in your container."
                    : "The user can only add new files to the container, they cannot change or delete existing files."
              }
              options={[
                {
                  value: "read",
                  label: "View Only",
                },
                {
                  value: "write",
                  label: "Write",
                },
                {
                  value: "manage",
                  label: "Manage",
                },
              ]}
            />

            <CustomSelect
              className="searchSelectContainer"
              regularInput
              label={true}
              labelText="Select a container"
              required={true}
              value={selectedContainerId}
              options={options}
              showSearch={true}
              onChange={(value) => handleChange("selectedContainerId", value)}
              // onChange={handleChange}
              defaultValue="Select a container"
            />
          </>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />
    </div>
  );
}

Members.propTypes = {
  searchTerm: PropTypes.string,
};
export default Members;
