/*eslint-disable*/
import {
  API_URL,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
} from "../../../constants/index.js";
import dayjs from "dayjs";
import "./DashboardGraph.scss";
import Graph from "./Graph.jsx";
// import Flatpickr from "react-flatpickr";
import { useSelector } from "react-redux";
import { Col, Row, Skeleton } from "antd";
import "flatpickr/dist/themes/material_blue.css";
import "../../../Pages/Dashboard/Dashboard.scss";
import httpRequest from "../../../Utils/httpsRequest.js";
import React, { useEffect, useRef, useState } from "react";
import useLogout from "../../../CustomHooks/useLogout.jsx";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { capitalizeFirstLetter } from "../../../Utils/helpers.js";
import { ResponsiveContainer, Pie, PieChart, Tooltip, Label } from "recharts";
import PropTypes from "prop-types";
import { setIsNew } from "../../../store/slices/authSlice.js";
import { useDispatch } from "react-redux";
// import { CustomTooltip } from "../../CustomTooltip/CustomTooltip .jsx";
import CustomSelect from "../CustomSelect/CustomSelect.jsx";
import ShowContainer from "../DashCard/ShowContainer.js";
import DashCardSekelton from "../DashCard/DashCardSekelton.jsx";
import { fetchContainers } from "../../../store/slices/containerSlice.js";
dayjs.extend(customParseFormat);
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: "#3b4040",
          paddingLeft: "15px",
          paddingRight: "15px",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <p>{`${name}: ${value} %`}</p>
      </div>
    );
  }
  return null;
};

function DashboardGraph() {
  const { csrfToken } = useSelector((state) => state.auth);
  const [dateRange, setDateRange] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [period, setPeriod] = useState("day");
  const handleLogOut = useLogout();
  const dispatch = useDispatch();
  const [mediaData, setMediaData] = useState({
    image: 0,
    video: 0,
    text: 0,
    audio: 0,
    application: 0,
  });
  const [metricFileCount, setMetricFileCount] = useState("No Data");
  const [data2, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [dropDownText, setDropDownText] = useState("1 Day");
  const [pieChartData, setPieChartData] = useState([]);
  const [pieDataExists, setPieDataExists] = useState(false);
  const { containers } = useSelector((state) => state.containers);

  const isDashboardGuideStore = localStorage.getItem("setUserIntroGuide");
  const { isDashboardGuide } = useSelector((state) => state.auth);

  /**Reset Date functionality in datepicker */
  const flatpickrRef = useRef(null);
  const crossRef = useRef(null);
  const clearDate = () => {
    setDateRange([]);
    flatpickrRef.current.flatpickr.clear();
    setShowReset(false);
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      setShowReset(true);
    }
  }, [dateRange]);

  useEffect(() => {
    if (flatpickrRef.current) {
      const flatpickrCalendar =
        flatpickrRef.current.flatpickr.calendarContainer;
      const monthsContainer =
        flatpickrCalendar.querySelector(".flatpickr-months");

      const currentMonthDiv = document.querySelector(
        ".flatpickr-current-month",
      );
      if (monthsContainer && showReset) {
        // Ensure we don't add multiple reset buttons

        if (!monthsContainer.querySelector(".custom-reset-button")) {
          const resetButton = document.createElement("button");
          currentMonthDiv.style.width = "90%";
          resetButton.textContent = "Reset";
          resetButton.className = "custom-reset-button";
          resetButton.style.cssText = `
          background: rgb(40, 38, 38);
          color: white;
          border: none;
          cursor: pointer;
          font-size: 15.7px;
          margin-right: 33px;
          margin-top: 5px;
        `;
          resetButton.addEventListener("click", clearDate);
          monthsContainer.appendChild(resetButton);
        }
      } else if (monthsContainer && !showReset) {
        const existingButton = monthsContainer.querySelector(
          ".custom-reset-button",
        );
        if (existingButton) {
          existingButton.remove();
          currentMonthDiv.style.width = "75%";
        }
      }
    }
  }, [showReset, flatpickrRef.current]);

  useEffect(() => {
    if (crossRef.current) {
      const elem = document.querySelector("#cross-icon");
      if (showReset) {
        crossRef.current.style.cursor = "pointer";
        elem.classList.remove("def-color");
        elem.setAttribute("class", "add-hover");
      } else {
        crossRef.current.style.cursor = "not-allowed";
        elem.setAttribute("class", "def-color");
        elem.classList.remove("add-hover");
      }
    }
  }, [showReset, crossRef.current]);

  const handlePeriod = (period, dropDownText) => {
    setPeriod(period);
    setDropDownText(dropDownText);
  };

  const getValue = (str, length, startIndex = 0) => {
    let tempString;
    if (length <= 3) {
      return str;
    }
    if (length - startIndex <= 3) {
      return str.substring(startIndex, length);
    }
    if ((length - startIndex) % 2 == 0) {
      tempString = str.charAt(startIndex);
      startIndex++;
    } else {
      tempString = str.substring(startIndex, startIndex + 2);
      startIndex += 2;
    }
    tempString += `,${getValue(str, length, startIndex)}`;
    return tempString;
  };

  const formatValue = (value) => {
    let str = value.toString();
    return getValue(str, str.length, 0);
  };

  useEffect(() => {
    const fetchMatriceData = async () => {
      try {
        const response = await httpRequest(
          API_URL.METRICS,
          HTTP_METHOD.GET,
          null,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },

          null,
          handleLogOut,
        );
        if (response?.code === HTTP_STATUS_CODE.OK) {
          let metricKeys = response?.data;
          let is_first = response?.data?.is_first;
          dispatch(setIsNew(is_first));
          setMetricFileCount(response?.data?.total);
          delete metricKeys.total;
          delete metricKeys.is_first;
          setData(metricKeys);
          setMediaData(response?.data);
        } else {
          throw new Error(response?.response?.data?.message ?? "");
        }
      } catch (error) {
        console.error("Error fetching matrix data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchMatriceData();
  }, []);

  useEffect(() => {
    const fetchUsageGraphData = async () => {
      try {
        const response = await httpRequest(
          `${API_URL.USAGE_GRAPH}?period=${period}`,
          HTTP_METHOD.GET,
          null,
          null,
          null,
          handleLogOut,
        );

        if (response?.code === HTTP_STATUS_CODE.OK) {
          if (response?.data?.uploads) {
            let formattedData = [];
            let index = 0;

            for (let data of response.data.uploads) {
              formattedData[index] = {
                date: data.date,
                upload_size: data.total_size,
                download_size: response.data.downloads[index].total_size,
              };

              index++;
            }

            setGraphData(formattedData);
          }
        } else {
          throw new Error(response?.response?.data?.message ?? "");
        }
      } catch (error) {
        console.error("Error fetching matrix data:", error);
      }
    };

    fetchUsageGraphData();
  }, [period]);

  useEffect(() => {
    const calculatePercentage = (count) => {
      if (metricFileCount === 0 || metricFileCount === "No Data") return 0;
      return ((count / metricFileCount) * 100).toFixed(2);
    };

    const radial_data = [
      {
        name: "Videos",
        value: calculatePercentage(mediaData?.video),
        fill: "#461959",
      },
      {
        name: "Texts",
        value: calculatePercentage(mediaData?.text),
        fill: "#FFF0F5",
      },
      {
        name: "Applications",
        value: calculatePercentage(mediaData?.application),
        fill: "#CD6688",
      },
      {
        name: "Audios",
        value: calculatePercentage(mediaData?.audio),
        fill: "#FBA1B7",
      },
      {
        name: "Images",
        value: calculatePercentage(mediaData?.image),
        fill: "#7A316F",
      },
    ];

    const formattedData = radial_data.map((item) => ({
      ...item,
      value: parseFloat(item.value),
    }));

    setPieChartData(formattedData);

    const isData = formattedData.some((item) => item.value > 0);
    setPieDataExists(isData);
  }, [mediaData]);

  const emptyData = [{ name: "No Data", value: 100, fill: "grey" }];

  const items = [
    {
      value: "day",
      label: "1 Day",
    },
    {
      value: "week",
      label: "1 Week",
    },
    {
      value: "month",
      label: "1 Month",
    },
    {
      value: "year",
      label: "1 Year",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(fetchContainers({ handleLogOut, csrfToken }));
      } catch (error) {
        console.error("Error fetching containers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {(containers.length == 0 && isDashboardGuide) ||
      (containers.length == 0 && isDashboardGuide) ? (
        <ShowContainer />
      ) : containers.length == 0 && isDashboardGuideStore ? (
        <ShowContainer />
      ) : null}
      <div className="dashGraph">
        {loading ? (
          <div className="dashGraph_leftsekelton">
            <div className="dashGraph_leftsekelton_top">
              <div className="dashGraph_leftsekelton_inner">
                <Skeleton
                  active
                  style={{ width: "200px" }}
                  paragraph={{
                    rows: 0,
                  }}
                />
                <Skeleton
                  active
                  style={{ width: "200px" }}
                  paragraph={{
                    rows: 0,
                  }}
                />
              </div>
              <Skeleton
                active
                style={{ width: "200px" }}
                paragraph={{
                  rows: 0,
                }}
              />
            </div>
            <div className="dashGraph_leftsekelton_bottom">
              <Skeleton.Node active>
                <div style={{ fontSize: 70, color: "#bfbfbf" }} />
              </Skeleton.Node>
            </div>
          </div>
        ) : (
          <div className="dashGraph_left">
            {/* <Tabs
                defaultActiveKey="1"
                items={items}
                tabBarExtraContent={OperationsSlot}
              /> */}
            <div className="content">
              <h2>Usage</h2>
              <CustomSelect
                defaultValue={dropDownText}
                options={items}
                onChange={(value, label) => handlePeriod(value, label)}
              />
              {/* <div className="content_inner">
                  <button onClick={() => handlePeriod("week")}>
                    set to week
                  </button>
                  <button onClick={() => handlePeriod("month")}>
                    set to month
                  </button>
                  <button onClick={() => handlePeriod("year")}>
                    set to year
                  </button>
                </div> */}
            </div>

            <Graph graphData={graphData} period={period} />
          </div>
        )}

        {loading ? (
          <div className="dashGraph_rightskeleton commonCardBg">
            <Skeleton.Node active />
            <div className="dashGraph_rightskeleton_inner">
              <Skeleton active title={{ width: 100 }} paragraph={{ rows: 0 }} />
              <div className="dashGraph_rightskeleton_inner_listing">
                <Row gutter={[60, 15]}>
                  {[...Array(3)].map((_, index) => (
                    <Col
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      key={index}
                      className="listingItems"
                    >
                      <Skeleton.Avatar active size="small" shape="circle" />
                      <Skeleton
                        active
                        title={{ width: 60 }}
                        paragraph={{ rows: 1, width: "80%" }}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <div className="dashGraph_right commonCardBg">
            <ResponsiveContainer
              width="100%"
              height={400}
              className="radial_graph"
            >
              <PieChart>
                {pieDataExists && <Tooltip content={<CustomTooltip />} />}
                <Pie
                  data={pieDataExists ? pieChartData : emptyData}
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  innerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  style={{ outline: "none" }}
                >
                  {pieDataExists ? (
                    <>
                      <Label
                        value="Total"
                        position="center"
                        dy={-10}
                        fill="white"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      />
                      <Label
                        value={formatValue(metricFileCount)}
                        position="center"
                        dy={20}
                        fill="white"
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      />{" "}
                    </>
                  ) : (
                    <Label
                      value="No Data"
                      position="center"
                      fill="white"
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    />
                  )}
                </Pie>
              </PieChart>
            </ResponsiveContainer>

            <div className="dashGraph_right_inner">
              <h3>Metrics</h3>
              <Row gutter={[60, 15]} className="dashGraph_right_inner_listing">
                {Object.keys(data2).map((key, index) => {
                  return (
                    <Col
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      key={index}
                      className="listingItems"
                    >
                      <span></span>
                      <p>{capitalizeFirstLetter(key)}</p>
                      <h4>{data2[key]}</h4>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default DashboardGraph;
// PropTypes for CustomTooltip
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
};
