import { useEffect } from "react";
import { BTN_LABLE } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import useLogout from "../../CustomHooks/useLogout";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { setWalletAddress } from "../../store/slices/authSlice";
import { setConnectButton } from "../../store/slices/uiSettingSlice";

const useWalletListner = () => {
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const { address } = useWeb3ModalAccount();
  const { walletAddress } = useSelector((state) => state.auth);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!address) {
        dispatch(setConnectButton(BTN_LABLE.CONNECT));
        handleLogOut();
      } else if (walletAddress && walletAddress !== address) {
        dispatch(setConnectButton(BTN_LABLE.CONNECT));
        handleLogOut();
      } else {
        dispatch(setWalletAddress(address));
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [address, walletAddress, dispatch]);

  return;
};

export default useWalletListner;
