import React, { useState } from "react";
import "./CreateNewApiModal.scss";
import CommonModal from "../../Common/CommonModal/CommonModal";
import { ModalPlusIcon, Eye, CloseEye, CopyIcon } from "../../Assets/Assets";
import PropTypes from "prop-types";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import dangerIcon from "../../Assets/images/Danger.svg";
import toast from "react-hot-toast";
function CreateNewApiModal({
  isModalVisible,
  closeModal,
  handleClick,
  generatedData,
}) {
  const [isHideAccessKey, setIsHideAccessKey] = useState(true);
  const [isHideSecretKey, setIsHideSecretKey] = useState(true);
  const [accessKeyType, setAccessKeyType] = useState("password");
  const [secretKeyType, setSecretKeyType] = useState("password");

  const toggleAccessKeyVisibility = () => {
    setIsHideAccessKey(!isHideAccessKey);
    setAccessKeyType(isHideAccessKey ? "text" : "password");
  };

  const toggleSecretKeyVisibility = () => {
    setIsHideSecretKey(!isHideSecretKey);
    setSecretKeyType(isHideSecretKey ? "text" : "password");
  };
  const accessHandleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(generatedData.access_key);
      toast.success("Access Key copied to clipboard!");
    } catch (error) {
      toast.error("Failed to copy Access Key");
    }
  };
  const access_secretHandleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(generatedData.access_secret);
      toast.success("Secret Key copied to clipboard!");
    } catch (error) {
      toast.error("Failed to copy Access Key");
    }
  };
  return (
    <CommonModal
      className="create-api shortWidthModal"
      visible={isModalVisible}
      title="Created API key"
      icon={<ModalPlusIcon />}
      onClick={handleClick}
      content={
        <>
          <div className="new_modal_input">
            <InputCustom
              label
              required
              regularInput
              minLength={2}
              maxLength={20}
              placeholder={generatedData.access_key}
              value={generatedData.access_key}
              error=""
              labletext="Access Key"
              customClass="new_api_input"
              rightBtn
              type={accessKeyType}
              rightIcon={isHideAccessKey ? <CloseEye /> : <Eye />}
              onclickIcon={toggleAccessKeyVisibility}
              onclickBtn={accessHandleCopyClick}
            />
            <span onClick={accessHandleCopyClick} className="copy_btn">
              <CopyIcon />
            </span>
          </div>

          <div className="secret_key_box">
            <h3>
              <img src={dangerIcon} alt="" />
              Secret Key
            </h3>
            <p>
              This information will be shown only once and cannot be retrieved
              once lost. Please store it properly
            </p>
          </div>

          <div className="new_modal_input">
            <InputCustom
              label
              required
              regularInput
              minLength={2}
              maxLength={20}
              placeholder={generatedData.access_secret}
              customClass="new_api_input"
              value={generatedData.access_secret}
              error=""
              labletext="Secret Key"
              type={secretKeyType}
              rightBtn
              rightIcon={isHideSecretKey ? <CloseEye /> : <Eye />}
              onclickIcon={toggleSecretKeyVisibility}
              onclickBtn={access_secretHandleCopyClick}
            />
            <span onClick={access_secretHandleCopyClick} className="copy_btn">
              <CopyIcon />
            </span>
          </div>

          <div className="note">
            <h2>Note</h2>
            <p>
              To avoid data breach, do not expose your access and secret keys to
              anyone.
            </p>
            <ButtonCustom
              className={"confirm_btn"}
              regularBtn
              onClick={closeModal}
              label="Done"
            />
          </div>
        </>
      }
      onOk={closeModal}
      onCancel={closeModal}
    />
  );
}

export default CreateNewApiModal;

CreateNewApiModal.propTypes = {
  isModalVisible: PropTypes.bool,
  closeModal: PropTypes.func,
  handleClick: PropTypes.func,
  generatedData: PropTypes.object,
};
