import {
  UserGroup,
  MoveToFolder,
  SettingsIcon,
  DashboardIcon,
} from "../../../Assets/Assets";
import "./SidebarDashboard.scss";
import PropTypes from "prop-types";
import HammerMenu from "./HammerMenu";
import React, { useEffect, useState } from "react";
import UpgradePlan from "../UpgradePlan/UpgradePlan";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../Assets/images/HeaderLogo.png";
import { Path } from "../../../Routing/Constant/RoutePaths";

function SidebarDashboard({ setDrawerState }) {
  const [alternatePath, setAlternatePath] = useState("");
  const location = useLocation();
  const exectPath = location.pathname;

  useEffect(() => {
    if (exectPath && exectPath?.includes("/folder-management")) {
      let str = exectPath.split("/");
      if (str?.length > 3) {
        setAlternatePath(`/folder-management/${str[2]}/${str[3]}`);
      }
    } else {
      setAlternatePath(null);
    }
  }, [location]);

  const menuItems = [
    {
      to: Path.DASHBOARD,
      icon: <DashboardIcon />,
      label: "Dashboard",
    },
    {
      to: Path.FILE_MANAGEMENT,
      alternate: alternatePath,
      icon: <MoveToFolder />,
      label: "File Management",
    },
    // {
    //   to: Path.ACCESSKEY,
    //   icon: <KeySquareIcon />,
    //   label: "Access Key",
    // },
    {
      to: Path.MEMBERS,
      icon: <UserGroup />,
      label: "Members",
    },
    // {
    //   to: Path.DAO,
    //   icon: <PieChartIcon />,
    //   label: "DAO",
    // },
    {
      to: Path.SETTINGS,
      icon: <SettingsIcon />,
      label: "Settings",
    },
  ];

  return (
    <div className="sidebar">
      <div className="sidebar_logo">
        <Link to={Path.DASHBOARD}>
          <img src={logo} alt="Logo" />
        </Link>
        <HammerMenu closed onClick={setDrawerState} />
      </div>
      <div className="sidebar_bottom">
        <div className="sidebar_link">
          <div className="sidebar_link_items">
            {menuItems.map((ele, index) => (
              <Link
                key={index}
                to={ele.to}
                className={
                  exectPath.startsWith(ele.to) ||
                  exectPath.startsWith(ele?.alternate)
                    ? "active"
                    : ""
                }
                onClick={setDrawerState}
              >
                {ele.icon}
                {ele.label}
              </Link>
            ))}
          </div>
        </div>

        <UpgradePlan />
      </div>
    </div>
  );
}

SidebarDashboard.propTypes = {
  setDrawerState: PropTypes.func,
};

export default SidebarDashboard;
