import React from "react";
import "./AccountCard.scss";
import { Flex, Skeleton } from "antd";
import PropTypes from "prop-types";
import faitIcon from "../../../Assets/images/dollarIocn.png";
import { TickRound } from "../../../Assets/Assets";
// import ComingSoon from "../../ComingSoon/ComingSoon";
import ButtonCustom from "../../Components/ButtonCustom/ButtonCustom";

function AccountCard(props) {
  const {
    data,
    icon,
    title,
    value,
    price,
    onClick,
    loading,
    tomiImg,
    duration,
    features,
    // btnActive,
    className,
    buttonText,
    btnDisabled,
    description,
    valueIconSrc,
  } = props;

  return (
    <div
      className={`accountCard ${className || ""}  ${loading ? "" : "hover"}`}
    >
      {loading ? (
        <>
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 2, width: "70%" }}
          />
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 5, width: "50%" }}
          />
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 3, width: "50%" }}
          />
          <Skeleton active title={false} paragraph={{ rows: 2 }} />
          <Skeleton.Button active size="large" block />
        </>
      ) : (
        <div className="accountCard_inner">
          <div>
            {" "}
            <div className="accountCard_tittle">
              {icon && icon}
              <h4>{title === "free" ? `${title} Plan` : `${title} Plan`}</h4>
            </div>
            <Flex gap={10} align="center" justify="flex-start" wrap > 
              <h2>
              {price && price}
              <span>/ {duration && duration}</span>
              </h2>
              {/* {TokenValue && ( */}
              <h2 className="amountContent">
                = <img src={tomiImg ? valueIconSrc : faitIcon} alt="" /> (
                <span className="value">{value && value}</span>
                <span>{tomiImg ? "TOMI" : "USDT"}</span>)
              </h2>
              {/* )} */}
            </Flex>
            {/* <h3>{note && note}</h3> */}
            <h3>{description && description}</h3>
            <div className="accountCard_border"></div>
            <div className="accountCard_includes">
              <h5>Includes:</h5>
              <ul>
                {features.map((feature, index) => (
                  <li key={index}>
                    <TickRound /> <p>{feature}</p>
                  </li>
                ))}
                {title === "free" ? (
                  <li>
                    <TickRound /> <p> No data encryption available</p>
                  </li>
                ) : (
                  <li>
                    <TickRound /> <p>Data encryption available</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="accountCard_btn">
            <ButtonCustom
              disabled={btnDisabled}
              className={` fluid_btn ${btnDisabled ? "disableBtn cursor-notAllowed" : ""} w-100`}
              onClick={() => onClick(data)}
              regularBtn
              label={buttonText && buttonText}
            />
          </div>
        </div>
      )}
    </div>
  );
}

AccountCard.propTypes = {
  icon: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  value: PropTypes.string,
  tomiImg: PropTypes.bool,
  // btnActive: PropTypes.bool,
  className: PropTypes.string,
  btnDisabled: PropTypes.bool,
  valueIconSrc: PropTypes.string,
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AccountCard.defaultProps = {
  loading: false,
};

export default AccountCard;
