import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../constants";
import httpRequest from "../../Utils/httpsRequest";

export const fetchApiKeys = createAsyncThunk(
  "apikey/fetchApiKeys",
  async ({ csrfToken, handleLogOut }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        API_URL.API_KEYS,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response.code === HTTP_STATUS_CODE.OK) {
        return response.data; // Assuming the data is an array of API keys
      } else {
        return rejectWithValue("Failed to fetch API keys");
      }
    } catch (error) {
      return rejectWithValue(error.message || "An unknown error occurred");
    }
  },
);

export const createNewApiKey = createAsyncThunk(
  "apikey/createNewApiKey",
  async ({ csrfToken, handleLogOut }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        API_URL.API_KEYS,
        HTTP_METHOD.POST,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );
      console.log("res:", response);

      if (response.code === HTTP_STATUS_CODE.OK) {
        return response.data;
      } else {
        throw new Error(
          response?.error?.message ?? "Failed  to create response",
        );
      }
    } catch (error) {
      return rejectWithValue(error.message || "An unknown error occurred");
    }
  },
);

export const updateApiKey = createAsyncThunk(
  "apikey/updateApiKey",
  async ({ k_id, status, csrfToken, handleLogOut }, { rejectWithValue }) => {
    try {
      const response = await httpRequest(
        `${API_URL.API_KEYS}/${k_id}`,
        HTTP_METHOD.PUT,
        { state: status },
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response.code === HTTP_STATUS_CODE.OK) {
        console.log(response.code);
        return { k_id, status: status };
      } else {
        return rejectWithValue("Failed to update API key status");
      }
    } catch (error) {
      return rejectWithValue(error.message || "An unknown error occurred");
    }
  },
);

// Slice
const apikeySlice = createSlice({
  name: "apikey",
  initialState: {
    apiKeys: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchApiKeys.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchApiKeys.fulfilled, (state, action) => {
      state.loading = false;
      state.apiKeys = action.payload;
    });
    builder.addCase(fetchApiKeys.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Handle creating an API key
    builder.addCase(createNewApiKey.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(createNewApiKey.fulfilled, (state, action) => {
      state.loading = false;
      state.apiKeys.push(action.payload);
    });
    builder.addCase(createNewApiKey.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateApiKey.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateApiKey.fulfilled, (state, action) => {
      state.loading = false;
      const { k_id, status } = action.payload;

      const index = state.apiKeys.findIndex(
        (key) => key?.k_id === k_id || key?.access_key === k_id,
      );

      if (index !== -1) {
        state.apiKeys[index].state = status;
      }
    });
    builder.addCase(updateApiKey.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default apikeySlice.reducer;
