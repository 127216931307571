import "./Dashboard.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowIcon } from "../../Assets/Assets";
import img from "../../Assets/images/LoginBanner.png";
import { Path } from "../../Routing/Constant/RoutePaths";
import { useLocation, useNavigate } from "react-router-dom";
import DashCard from "../../Common/Components/DashCard/DashCard";
import UserLogCard from "../../Common/Components/UserLogCard/UserLogCard";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import DashboardGraph from "../../Common/Components/DashboardGraph/DashboardGraph";
import ProcessPaymentModal from "../../Common/Components/UpgradePlan/ProcessPaymentModal/ProcessPaymentModal";
import { setPaymentProcessModal } from "../../store/slices/userSlice";

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { planType, paymentProcessModal } = useSelector((state) => state.user);
  const { isDashboardGuide } = useSelector((state) => state.auth);
  const isDashboardGuideStore = localStorage.getItem("setUserIntroGuide");
  const { pathname } = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (paymentProcessModal) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [paymentProcessModal]);

  const closeModal = () => {
    dispatch(setPaymentProcessModal(false));
    setActive(false);
  };

  function changePath() {
    navigate(Path.PLAN);
  }
  //  console.log("planType::",planType);

  return (
    <div className="dashboard">
      <div className="dashboard_banner">
        <img src={img} alt="Dashboard Banner" />
        <div>
          <h3>Welcome to Storage</h3>
          <p>
            Begin by setting up folders to keep your files organized and easily
            accessible.
          </p>
        </div>
        {planType === "scale" ? null : (
          <ButtonCustom
            borderBtn
            label="Upgrade Plan"
            onClick={changePath}
            icon={<ArrowIcon />}
          />
        )}
      </div>

      {!isDashboardGuide &&
        !isDashboardGuideStore &&
        pathname === "/dashboard" &&
        planType != null && <DashCard />}

      <DashboardGraph />

      <UserLogCard />
      <ProcessPaymentModal isModalVisible={active} closeModal={closeModal} />
    </div>
  );
}

export default Dashboard;
