import EventEmitter from "events";
import { FILE_UPLOAD_STATUS } from "../constants";
import { uploadFiles, uploadFolder } from "../store/slices/FolderSlice";

class Queue extends EventEmitter {
  constructor(updateFileStatus, dispatch, csrfToken) {
    if (Queue.instance) {
      return Queue.instance;
    }

    super();
    this.queue = [];
    this.updateFileStatus = updateFileStatus;
    this.dispatch = dispatch;
    this.csrfToken = csrfToken;
    this.controllers = new Map();

    Queue.instance = this;
  }

  enqueue(task) {
    const controller = new AbortController();
    task.controller = controller;
    this.queue.push(task);
    this.controllers.set(task.id, controller);

    if (this.queue.length === 1) {
      this.processQueue();
    }
  }
  static dequeueStatic(id) {
    const instance = Queue.instance;
    if (!instance) {
      console.error("Queue instance does not exist");
      return;
    }
    const task = instance.controllers.get(id);
    if (task) {
      task.abort();
      console.log(`Task ${id} aborted`);
    }
    instance.queue = instance.queue.filter((item) => item.id !== id);
    instance.controllers.delete(id);
    instance.emit("uploadFail", id, FILE_UPLOAD_STATUS.REJECTED);
  }

  async uploadSingleFile(data) {
    if (data?.type === "file") {
      try {
        const formDataBody = new FormData();

        formDataBody.append("mediable_id", data.mediable_id);
        formDataBody.append("files", data?.file);
        formDataBody.append("is_encrypted", data.is_encrypted);
        const response = await this.dispatch(
          uploadFiles({
            formDataBody,
            handleLogOut: data.handleLogOut,
            csrfToken: this.csrfToken,
            signal: data.controller.signal,
          }),
        );

        if (response?.payload?.error) {
          //this.emit("uploadFail", data.id, FILE_UPLOAD_STATUS.REJECTED);
          return { isError: true, status: "failed" };
        } else {
          //this.emit("uploadSuccess", data.id, FILE_UPLOAD_STATUS.DONE);
          return { isError: false, status: "done" };
        }
      } catch (error) {
        console.error("File upload error: ", error);
      }
    } else {
      try {
        const formDataBody = new FormData();
        formDataBody.append("container_id", data.container_id);
        formDataBody.append("is_encrypted", data.is_encrypted);

        if (data?.container_id !== data?.parent_id) {
          formDataBody.append("parent_id", data.parent_id);
        }

        let isHtml = false;
        let size = 0;
        for (let file of data.file) {
          if (file?.type === "text/html") {
            isHtml = true;
          }
          size += file?.size;
          formDataBody.append(file.webkitRelativePath, file);
        }

        if (data?.file_length) {
          formDataBody.append("file_length", data.file_length);
        }
        formDataBody.append("size", size);
        formDataBody.append("isHtml", isHtml);

        const response = await this.dispatch(
          uploadFolder({
            formDataBody,
            handleLogOut: data.handleLogOut,
            csrfToken: this.csrfToken,
            signal: data.controller.signal,
          }),
        );

        if (response?.payload?.error) {
          this.emit("uploadFail", data.id, FILE_UPLOAD_STATUS.REJECTED);
          return { isError: "true", status: "failed" };
        } else {
          this.emit("uploadSuccess", data.id, FILE_UPLOAD_STATUS.DONE);

          return { isError: "false", status: "done" };
        }
      } catch (error) {
        console.error("Folder upload error: ", error);
        return { isError: "true", status: "failed" };
      }
    }
  }

  async processQueue() {
    if (this.queue.length === 0) {
      this.emit("queueEmpty", "queue is empty");
      return;
    }

    const currentTask = this.queue[0];
    try {
      const res = await this.uploadSingleFile(currentTask);
      if (res.status === "failed") {
        this.emit("uploadFail", currentTask.id, FILE_UPLOAD_STATUS.REJECTED);
      } else {
        this.emit("uploadSuccess", currentTask.id, FILE_UPLOAD_STATUS.DONE);
      }
      this.queue.shift();
      setTimeout(() => this.processQueue(), 1000);
    } catch (error) {
      console.error("Task processing error:", error);
      this.queue.shift();
      setTimeout(() => this.processQueue(), 1000);
    }
  }

  clearAllTasks() {
    this.queue = [];
  }
}

export default Queue;
