import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import uiSettings from "./slices/uiSettingSlice";
import { configureStore } from "@reduxjs/toolkit";
import authSliceReducer from "./slices/authSlice";
import userSliceReducer from "./slices/userSlice";
import foldersReducer from "./slices/FolderSlice";
import settingsSlice from "./slices/settingsSlice";
import viewSliceReducer from "./slices/toggleSlice";
import containerReducer from "./slices/containerSlice";
import breadcrumbReducer from "./slices/breadcrumbSlice";
import shareingReducer from "./slices/shareSlice";
import notificationReducer from "./slices/notificationSlice";
import planReducer from "./slices/planSlice";
import userGuideReducer from "./slices/userGuideSlice";
import apikeyReducer from "./slices/apikeySlice";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user", "breadcrumb", "userGuide"],
  blacklist: [], // You can list any slices here that you don't want persisted
};

const rootReducer = combineReducers({
  auth: authSliceReducer,
  user: userSliceReducer,
  view: viewSliceReducer,
  containers: containerReducer,
  folders: foldersReducer,
  breadcrumb: breadcrumbReducer,
  settings: settingsSlice,
  uiSettings: uiSettings,
  shareing: shareingReducer,
  notifications: notificationReducer,
  plan: planReducer,
  userGuide: userGuideReducer,
  apikey: apikeyReducer,

  // Add other reducers here
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

export const persistor = persistStore(store);
export default store;
