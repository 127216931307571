import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: null,
  name: null,
  userId: null,
  planId: null,
  nextPlan: null,
  planType: null,
  planExpiry: null,
  bannerShow: true,
  paymentPlan: null,
  paymentProcessModal: false,
  showContainerModal: false,
  containerSharedTabVisible: false,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { email, name, id, plan_type, plan_expiry } = action.payload;
      state.email = email;
      state.name = name;
      state.userId = id;
      state.planType = plan_type ?? null;
      state.planExpiry = plan_expiry ?? null;
      state.nextPlan = null;
    },
    setContainerSharedTabVisible: (state, action) => {
      state.containerSharedTabVisible = action.payload;
    },
    setPaymentProcessModal: (state, action) => {
      state.paymentProcessModal = action.payload;
    },
    setPlanType: (state, action) => {
      state.planType = action.payload ?? null;
    },
    setNextPlan: (state, action) => {
      state.nextPlan = action.payload;
    },
    setPlanId: (state, action) => {
      state.planId = action.payload;
    },
    setPaymentPlan: (state, action) => {
      state.paymentPlan = action.payload;
    },
    setPlanExpiry: (state, action) => {
      state.planExpiry = action.payload;
    },
    setBanner: (state, action) => {
      state.bannerShow = action.payload;
    },
    setContainerModal: (state, action) => {
      state.showContainerModal = action.payload;
    },
    clearUserData: (state) => {
      state.email = null;
      state.name = null;
      state.nextPlan = null;
      state.userId = null;
      state.planType = null;
      state.planExpiry = null;
    },
  },
});
export const {
  setBanner,
  setPlanId,
  setUserData,
  setPlanType,
  setNextPlan,
  clearUserData,
  setPlanExpiry,
  setPaymentPlan,
  setContainerModal,
  setPaymentProcessModal,
  setContainerSharedTabVisible,
} = userSlice.actions;
export const selectUserEmail = (state) => state.user.email;
export default userSlice.reducer;
