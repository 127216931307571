import PropTypes from "prop-types";
import React, { createContext, useState } from "react";
import { FILE_UPLOAD_STATUS } from "../../constants";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [uploadFileQueueDulicate, setUploadFileQueueDuplicate] = useState([]);
  const [uploadFileQueue, setUploadFileQueue] = useState([]);
  const [uploadFailed, setUploadFailed] = useState([]);
  const [uploadSucessfully, setUploadSucessfully] = useState([]);

  const updateFileStatus = (id, newStatus) => {
    const fileToUpdate = uploadFileQueue.find((file) => file.id === id);
    if (!fileToUpdate) return false;

    setUploadFileQueue((prevQueue) =>
      prevQueue.filter((file) => file.id !== id),
    );

    switch (newStatus) {
      case FILE_UPLOAD_STATUS.DONE:
        setUploadSucessfully((prevSuccess) => {
          if (!prevSuccess.some((file) => file.id === id)) {
            return [
              ...prevSuccess,
              { ...fileToUpdate, status: FILE_UPLOAD_STATUS.DONE },
            ];
          }
          return prevSuccess;
        });
        setUploadFileQueue((prevQueue) =>
          prevQueue.filter(
            (file) =>
              !uploadSucessfully.some((f) => f.id === file.id) &&
              !uploadFailed.some((f) => f.id === file.id),
          ),
        );

        break;

      case FILE_UPLOAD_STATUS.REJECTED:
        setUploadFailed((prevFailed) => {
          if (!prevFailed.some((file) => file.id === id)) {
            return [
              ...prevFailed,
              { ...fileToUpdate, status: FILE_UPLOAD_STATUS.REJECTED },
            ];
          }
          return prevFailed;
        });
        setUploadFileQueue((prevQueue) =>
          prevQueue.filter(
            (file) =>
              !uploadSucessfully.some((f) => f.id === file.id) &&
              !uploadFailed.some((f) => f.id === file.id),
          ),
        );

        break;
      default:
        console.warn("Unknown status:", newStatus);
        break;
    }

    return true;
  };

  const clearUploadFileQueue = () => {
    setUploadFileQueue([]);
    setUploadSucessfully([]);
    setUploadFailed([]);
    setUploadFileQueueDuplicate([]);
  };

  const contextValue = {
    setUploadFileQueue,
    uploadFileQueueDulicate,
    setUploadFileQueueDuplicate,
    updateFileStatus,
    clearUploadFileQueue,
    uploadFileQueue,
    uploadFailed,
    uploadSucessfully,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
};
