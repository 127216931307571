import React from "react";
import { Modal } from "antd";
import "./CommonModal.scss";
import PropTypes from "prop-types";
import { ArrowIcon } from "../../Assets/Assets";
import ButtonCustom from "../Components/ButtonCustom/ButtonCustom";
const CommonModal = ({
  visible,
  title,
  icon,
  customBtutton,
  content,
  BtnText,
  className,
  onOk,
  onCancel,
  paragraph,
  onClick,
  btnDisabled,
}) => {
  return (
    <Modal
      open={visible}
      onOk={onOk}
      centered
      onCancel={onCancel}
      footer={null}
      className={`${className} common-modal`}
    >
      <div className="modal-header">
        <div className="icon">{icon}</div>
        <div className="title">{title}</div>
      </div>
      <div className="modal-content">
        {paragraph && <p className="modal-content-paragraph">{paragraph}</p>}
        {content}
        {customBtutton && (
          <div className="modal-content-botton">
            <ButtonCustom
              disabled={btnDisabled}
              className={btnDisabled ? "disableBtn" : ""}
              onClick={onClick}
              regularBtn
              label={BtnText}
              icon={<ArrowIcon />}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

CommonModal.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  content: PropTypes.node,
  customBtutton: PropTypes.node,
  BtnText: PropTypes.string,
  onOk: PropTypes.func,
  visible: PropTypes.bool,
  className: PropTypes.string,
  paragraph: PropTypes.node,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  btnDisabled: PropTypes.bool,
};

export default CommonModal;
