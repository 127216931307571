export const REGEX = {
  NAME: /^[\p{L}\p{M}\p{Zs}'\-0-9]+$/u,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/,
  CONTAINER_NAME: /^[a-zA-Z0-9-_]+$/,
  STARTS_WITH_LETTER: /^[\p{L}]/u,
  PLUS_IN_LOCALPART: /^[^@]*\+/,
};

export const ERR_MSG = {
  REQUIRED_INPUT: "Required field is empty.",
  INVALID_NAME:
    "Name must contain only letters, digits, spaces, hyphens (-), and apostrophes (')",
  CONSECUTIVE_SPACE: "Name should not contain consecutive spaces.",
  SHORT_LENGTH: "Name must be at least 3 characters long.",
  LONG_LENGTH: "Name must not exceed 50 characters.",
  ALPHANUMERIC_CHARACTERS: "Name should not contain any symbol.",
  INVALID_PASSWORD:
    "Password must be at least 8 characters long, with at least 1 letter and 1 number.",
  INVALID_PHONE: "Please enter a valid 10-digit phone number.",
  PASSWORD_MISMATCH: "Passwords do not match.",
  INVALID_EMAIL: "Please enter a valid email address.",
  PLUS_ADDRESSING_NOT_ALLOWED:
    "Plus addressing (emails with the ‘+’ symbol) is not allowed. Please enter a valid email.",
  STARTS_WITH_LETTER: "Name must start with a letter",
  CONTAINER_MESSAGE: "Container name must be at least 3 characters long",
  FOLDER_MESSAGE: "Folder name must be at least 3 characters long",
  NOT_SUPPORTED_MAIL:
    "Your email domain is not supported. Please use an email address from a recognized provider.",
};

export const API_URL = {
  FORGOT_PASSWORD: "/v1/public/forgot_account",
  LOGIN: "/v1/public/login",
  REGISTER: "/v1/public/register_user",
  RESEND_OTP: "/v1/public/resend_otp",
  VALIDATE_OTP: "/v1/public/validate_otp",
  LOGOUT: "/v1/user/logout",
  CREATE_CONTAINER: "/v1/user/container",
  GET_ALL_CONTAINERS: "/v1/user/containers",
  UPDATE_CONTAINER: "/v1/user/container",
  CREATE_FOLDER: "/v1/user/folder",
  GET_ALL_FOLDER: "v1/user/container/",
  GET_NESTED_FOLDER: "/v1/user/folder/",
  UPDATE_FOLDER: "/v1/user/folder",
  UPLOAD_FILES: "/v1/user/upload_media",
  UPLOAD_FOLDER: "/v1/user/folder/upload",
  METRICS: "/v1/user/dashboard/metrics",
  USER_LOGS: "/v1/user/dashboard/logs",
  EDIT_USER: "/v1/user/edit-profile",
  DOWNLOAD_FILES: "/v1/user/download_media/",
  USAGE_GRAPH: "/v1/user/dashboard/graph",
  DELETE_CONTAINER: "/v1/user/container/",
  DELETE_FOLDER: "/v1/user/folder/",
  DELETE_MEDIA: "/v1/user/media/",
  SHARE_ITEM: "/v1/member",
  SHARE_CONTAINER_WITH_ME: "/v1/member/containers",
  SHARE_CONTAINER_BY_ME: "/v1/member",
  REMOVE_MEMBER: "/v1/member/",
  CHANGE_ROLE: "/v1/member/",
  MEDIA_DETAILS: "/v1/public/media/",
  DOWNLOAD_PUBLIC_MEDIA: "/v1/public/download_media/",
  GET_SHARED_MEDIA: "/v1/member/shared-resources/",
  IS_PUBLICLY_SHARED: "/v1/member/",
  UNSHARE_PUBLICLY: "/v1/member/unshare/",
  WS_NOTIFICATION: "/v1/ranger/private?event=subscribe&stream=notification",
  SUBSCRIPTION_STATUS:
    "/v1/ranger/private?event=subscribe&stream=subscription_status",
  WS_STORAGE_USAGE: "/v1/ranger/private?event=subscribe&stream=storage_update",
  GET_NOTIFICATION: "/v1/user/notification",
  UPDATE_NOTIFICATION: "v1/user/notification",
  PLAN: "/v1/plans",
  BILLING_LIST: "/v1/user/payment",
  COIN_MARKET_CAP: "/v1/user/price",
  CONTACT_US: "/v1/public/contact-us",
  REPORT_MEDIA: "/v1/public/report_media",
  USER_GUIDE_SHOWN: "v1/user/user-guide",
  GET_STORAGE: "v1/user/dashboard/storage",
  TRIAL_PLAN: "/v1/user/subscription/trial",
  PAID_PLAN: "/v1/user/quote",
  TOKEN_ADDRESSES: "/v1/public/get-tokens",
  INVITE: "/v1/user/invite",
  API_KEYS: "/v1/user/api-key",
};

export const HTTP_STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  UNPROCESSABLE_ENTITY: 422,
};

export const METAMASK_ERR_CODES = {
  REJECT: 4001,
};

export const HTTP_HEADER = {
  default: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
  upload: {
    "Content-Type": "multipart/form-data",
    Accept: "*/*",
  },
};

export const HTTP_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const INFINITE_SCROLLBAR = {
  DEFAULT_LIMIT: 20,
  DEFAULT_PAGE: 1,
};

export const BTN_LABLE = {
  DISCONNECT: "Disconnect",
  CONNECT: "Connect Wallet",
};

export const OPERATIONS = {
  open: "open",
  edit: "edit",
  view: "view",
  share: "share",
  delete: "delete",
  remove: "remove",
  details: "details",
  download: "download",
  changeAccess: "chageRole",
};

export const FILE_UPLOAD_STATUS = {
  DONE: "done",
  IN_QUEUE: "inQueue",
  IN_PROGRESS: "inprogress",
  REJECTED: "rejected",
};
