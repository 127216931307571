import React from "react";
import PropTypes from "prop-types";
import "./ProcessPaymentModal.scss";
import CommonModal from "../../../CommonModal/CommonModal";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import procesingImg from "../../../../Assets/images/payment-processing.png";

function ProcessPaymentModal({ isModalVisible, closeModal }) {
  return (
    <CommonModal
      className="payment-process-modal shortWidthModal"
      visible={isModalVisible}
      content={
        <>
          <div className="circle">
            <div className="loader-circle"></div>
            <img className="proces_img" src={procesingImg} alt="" />
          </div>
          <h2>Payment Processing</h2>
          <p>Your payment is being processed. Please wait a moment.</p>
          <ButtonCustom
            className={"ok_btn"}
            regularBtn
            onClick={closeModal}
            label="OK"
          />
        </>
      }
      onOk={closeModal}
      onCancel={closeModal}
    />
  );
}

export default ProcessPaymentModal;

ProcessPaymentModal.propTypes = {
  isModalVisible: PropTypes.bool,
  closeModal: PropTypes.func,
};
