import React from "react";
import "./Notification.scss";
import { Empty, Table, Tooltip } from "antd";
import { BellIcon, DeletedFile, PlateIcon, Upload } from "../../Assets/Assets";
import NoData from "../../Assets/images/notfound.svg";
import {
  convertDate,
  convertDateFormat,
  formatString,
} from "../../Utils/helpers";
import { updateNotificationStatus } from "../../store/slices/notificationSlice";
import useLogout from "../../CustomHooks/useLogout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToBreadcrumb,
  resetBreadcrumb,
} from "../../store/slices/breadcrumbSlice";

function Notification() {
  const dispatch = useDispatch();
  const handleLogout = useLogout();
  const navigate = useNavigate();

  const { csrfToken } = useSelector((state) => state.auth);
  const { notifications = [] } = useSelector((state) => state.notifications);

  const data =
    (notifications &&
      notifications?.map((notification, index) => ({
        ...notification,
        key: index.toString(),
        Title: (
          <div className="profile">
            {notification?.shareable_type === "upload" && <Upload />}
            {notification?.shareable_type === "delete" && <DeletedFile />}
            {notification?.shareable_type === "payment" && <PlateIcon />}
            <BellIcon />
            {notification?.content.length > 60 ? (
              <Tooltip title={notification?.content}>
                <p>
                  {formatString(notification?.content, 60, 0) ||
                    "No Content Available"}
                </p>
              </Tooltip>
            ) : (
              <p>{notification?.content || "No Content Available"}</p>
            )}
          </div>
        ),
        name: notification?.content || "",

        shareable_id: notification?.shareable_id,
        access_level: notification?.access_level,
        DateTime: convertDate(notification?.created_at),
        notification_name: notification?.shareable_name,

        Action:
          notification?.shareable_type === "container" ||
          notification?.shareable_type === "folder" ? (
            <div className="options">
              <button
                className="btn_open"
                onClick={() => {
                  notificationStatusUpdate(notification?.id);
                  navigate(
                    `/folder-management/${notification?.shareable_type}/${notification?.shareable_id}`,
                  );
                }}
              >
                Open
              </button>
            </div>
          ) : null,
      }))) ||
    [];

  const handleNavigation = (item) => {
    if (item?.shareable_type !== "media") {
      dispatch(resetBreadcrumb());
      dispatch(
        addToBreadcrumb({
          id: `folder-management/${item?.shareable_type}/${item?.shareable_id}`,
          name: item?.notification_name,
          container_access_level: item?.access_level,
        }),
      );

      navigate(
        `/folder-management/${item?.shareable_type}/${item?.shareable_id}`,
      );
    }
  };

  const columns = [
    {
      width: "25%",
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      sorter: (a, b) => {
        const nameA = a.name || "";
        const nameB = b.name || "";
        return nameA.localeCompare(nameB);
      },
      onCell: (record) => ({
        onClick: () => handleNavigation(record),
      }),
    },
    {
      width: "25%",
      title: "Date & Time",
      dataIndex: "DateTime",
      key: "DateTime",
      onCell: (record) => ({
        onClick: () => handleNavigation(record),
      }),
      sorter: (a, b) =>
        new Date(convertDateFormat(a.DateTime)) -
        new Date(convertDateFormat(b.DateTime)),
    },
    {
      width: "25%",
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
  ];

  const notificationStatusUpdate = async (notificationId) => {
    if (!notificationId) return;
    try {
      await dispatch(
        updateNotificationStatus({ notificationId, csrfToken, handleLogout }),
      );
    } catch (error) {
      console.error("Error while updating notification status: ", error);
    }
  };

  return (
    <Table
      responsive
      columns={columns}
      dataSource={data}
      pagination={false}
      className="commontable notificationsTable"
      tableLayout="auto"
      // scroll={{ y: 550, x: true }}
      locale={{
        emptyText: (
          <Empty
            image={<img src={NoData} alt="empty" />}
            description="No Data Found"
          />
        ),
      }}
      showSorterTooltip={{
        target: "sorter-icon",
      }}
    />
  );
}

export default Notification;
