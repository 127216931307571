import React from "react";
import "./HeaderCommon.scss";
import HeaderLogo from "../../../Assets/images/HeaderLogo.png";
import ButtonCustom from "../ButtonCustom/ButtonCustom";
import PropTypes from "prop-types";
import { SignOutIcon } from "../../../Assets/Assets";
import httpRequest from "../../../Utils/httpsRequest";
import { API_URL, HTTP_METHOD, HTTP_STATUS_CODE } from "../../../constants";
import { clearUploadFileQueue } from "../../../store/slices/uiSettingSlice";
import { useSelector } from "react-redux";
import useLogout from "../../../CustomHooks/useLogout";

function HeaderCommon({ SignUpBtn, DisconnectBtn }) {
  const handleLogout = useLogout();
  const { csrfToken } = useSelector((state) => state.auth);

  const handleSignOut = async () => {
    try {
      const response = await httpRequest(
        API_URL.LOGOUT,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogout,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        handleLogout();
        clearUploadFileQueue();
      } else {
        throw new Error(response?.response?.data?.message ?? "");
      }
    } catch (error) {
      console.log("Error while signing out: ", error);
    }
  };
  return (
    <div className="headerCommon">
      <img src={HeaderLogo} alt="Logo" />
      {SignUpBtn && (
        <div className="headerCommon_signBtn">
          <ButtonCustom regularBtn label="Sign Up" />
        </div>
      )}
      {DisconnectBtn && (
        <ButtonCustom
          regularBtn
          className="disconnect_btn"
          label={"Disconnect"}
          onClick={handleSignOut}
          lefticon={<SignOutIcon />}
        />
      )}
    </div>
  );
}

export default HeaderCommon;
HeaderCommon.propTypes = {
  SignUpBtn: PropTypes.bool,
  DisconnectBtn: PropTypes.bool,
};
