import React, { useContext, useState } from "react";
import {
  CrossIcon3,
  DownArrow,
  UpArrow,
  UploadPinkIcon,
} from "../../../Assets/Assets";
import { Tabs } from "antd";
import Uploading from "../../../Assets/images/Uploading.svg";
import UploadSec from "./UploadSec";
import { AppContext } from "../../../store/Context/index.js";
import { formatString } from "../../../Utils/helpers.js";
import Queue from "../../../Utils/eventEmitter.js";

function UploadPopupNew() {
  const {
    uploadFileQueue,
    clearUploadFileQueue,
    uploadSucessfully,
    uploadFailed,
    uploadFileQueueDulicate,
  } = useContext(AppContext);
  const [isClosed, setIsClosed] = useState(false);

  const handlePopupClose = () => {
    setIsClosed((prevState) => !prevState);
  };

  const handleClear = () => {
    clearUploadFileQueue();
  };

  const handleRemovefromQueue = (id) => {
    Queue.dequeueStatic(id);
  };

  const tabItems = [
    {
      key: "1",
      label: "All uploads",
      children: (
        <div className="uploadList">
          {[...uploadFileQueue, ...uploadSucessfully, ...uploadFailed]?.length >
          0 ? (
            [...uploadFileQueue, ...uploadSucessfully, ...uploadFailed].map(
              (upload) => (
                <UploadSec
                  key={`${upload.id}-${upload.status}`}
                  title={
                    upload?.type === "folder"
                      ? (() => {
                          const folderName =
                            upload?.file[0]?.webkitRelativePath.split("/")[0];
                          return folderName?.length > 35
                            ? formatString(folderName, 17, 17)
                            : folderName;
                        })()
                      : upload?.file?.name || "Unnamed File"
                  }
                  UploadStatus={upload.status}
                  containerName={
                    upload?.containerName.length > 15
                      ? formatString(upload?.containerName, 4, 4)
                      : upload?.containerName
                  }
                  progress={upload?.status}
                  onClose={() => handleRemovefromQueue(upload.id)}
                />
              ),
            )
          ) : (
            <div>No uploads available</div>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "Completed",
      children: (
        <>
          <div className="uploadList">
            {uploadSucessfully?.length > 0 ? (
              uploadSucessfully.map((upload) => (
                <UploadSec
                  key={upload.id}
                  title={
                    upload?.type === "folder"
                      ? (() => {
                          const folderName =
                            upload?.file[0]?.webkitRelativePath.split("/")[0];
                          return folderName?.length > 35
                            ? formatString(folderName, 17, 17)
                            : folderName;
                        })()
                      : upload?.file?.name || "Unnamed File"
                  }
                  UploadStatus={upload.status}
                  containerName={
                    upload?.containerName.length > 15
                      ? formatString(upload?.containerName, 4, 4)
                      : upload?.containerName
                  }
                  progress={upload?.status}
                  onClose={() => handleRemovefromQueue(upload.id)}
                />
              ))
            ) : (
              <div>No uploads available</div>
            )}
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: "Failed",
      children: (
        <>
          <div className="uploadList">
            {uploadFailed?.length > 0 ? (
              uploadFailed.map((upload) => (
                <UploadSec
                  key={upload.id}
                  title={
                    upload?.type === "folder"
                      ? (() => {
                          const folderName =
                            upload?.file[0]?.webkitRelativePath.split("/")[0];
                          return folderName?.length > 35
                            ? formatString(folderName, 17, 17)
                            : folderName;
                        })()
                      : upload?.file?.name || "Unnamed File"
                  }
                  UploadStatus={upload.status}
                  containerName={
                    upload?.containerName.length > 15
                      ? formatString(upload?.containerName, 4, 4)
                      : upload?.containerName
                  }
                  progress={upload?.status}
                  onClose={() => handleRemovefromQueue(upload.id)}
                />
              ))
            ) : (
              <div>
                No uploads available
              </div> /* Optional: Replace with a meaningful message or empty state component */
            )}
          </div>
        </>
      ),
    },
    // {
    //   key: "4",
    //   label: "Cancel",
    //   children: <>Cancel</>,
    // },
  ];
  return (
    <div className="uploadPopup">
      <div className="uploadPopup_header">
        <div>
          <UploadPinkIcon />
          <h5 className="title">Uploading Files</h5>
        </div>
        <div className="uploadPopup_right">
          <div className="uploadPopup_icon" onClick={handlePopupClose}>
            {isClosed ? <UpArrow /> : <DownArrow />}
          </div>
          <div className="uploadPopup_icon" onClick={handleClear}>
            <CrossIcon3 />
          </div>
        </div>
      </div>
      <div>
        <Tabs
          className={`uploadTabs ${isClosed ? "closed" : "open"}`}
          defaultActiveKey="1"
          items={tabItems}
        />
      </div>
      <div className="uploadingStatus">
        <img src={Uploading} alt="" />
        <h5>
          {uploadSucessfully?.length} of {uploadFileQueueDulicate.length}{" "}
          uploads complete
        </h5>
      </div>
    </div>
  );
}

export default UploadPopupNew;
