import "./App.scss";
import React from "react";
import { env } from "./constants/env.js";
import { Toaster } from "react-hot-toast";
import { getCookie } from "./Utils/helpers.js";
import MainLoader from "./Common/Loader/Loader.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setCsrfToken } from "./store/slices/authSlice.js";
import WalletListener from "./Common/WalletListener/index.jsx";
import { publicRoutes, privateRoutes } from "./Routing/Routes.jsx";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { defaultConfig, createWeb3Modal } from "@web3modal/ethers/react";

function App() {
  const dispatch = useDispatch();
  const csrfToken = getCookie("X-CSRF-Token");
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { mainLoading } = useSelector((state) => state.uiSettings);
  dispatch(setCsrfToken(csrfToken));

  const router = createBrowserRouter([
    {
      path: "/",
      children: isLoggedIn ? privateRoutes : publicRoutes,
      errorElement: (
        <center>
          <h1>Something went wrong! Try after refreshing!</h1>
        </center>
      ),
    },
  ]);

  const initWallet = async () => {
    try {
      const customNetwork = {
        chainId: Number(env.chainId),
        name: env.chainName,
        currency: env.chainCurrency,
        explorerUrl: env.explorerUrl,
        rpcUrl: env.ethRpcUrl,
      };

      const metadata = {
        name: env.metaDataName,
        description: env.metaDataName,
        url: env.metaDataWebsiteUrl,
        icons: [env.metaDataIconWebsiteUrl],
      };

      const ethersConfig = defaultConfig({
        /*Required*/
        metadata,

        /*Optional*/
        enableEIP6963: true, // true by default
        enableInjected: true, // true by default
        enableCoinbase: true, // true by default
        rpcUrl: "...", // used for the Coinbase SDK
        defaultChainId: Number(env.defaultChainId),
      });

      const modal = createWeb3Modal({
        ethersConfig,
        chains: [customNetwork],
        projectId: env.walletConnectProjectId,
        enableAnalytics: false,
        allWallets: "HIDE",
      });

      modal.setConnectors(
        [...modal.getConnectors()].filter(
          (wallet) => wallet?.name !== "Trust Wallet",
        ),
      );
    } catch (error) {
      // console.log("Error while initializing wallet", error);
    }
  };

  initWallet();
  // return (<center className="">
  //   <div className="errorPage">
  //     <h1>Something went wrong! Try after refreshing!</h1>
  //   </div>
  // </center>)
  return (
    <>
      <WalletListener />
      {mainLoading && <MainLoader />}
      <Toaster
        position="top-center"
        theme="dark"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "toastOptions",
          duration: 3000,
          style: {
            background: "#ff0083",
            color: "#fff",
          },

          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
