import { Spin, Tabs, Tooltip, Skeleton, Dropdown, Checkbox, Modal } from "antd";
import {
  ThreeDots,
  CopyIcon,
  ShareIcon,
  PlusIcon,
  FolderImg,
  ManageMenu,
  DeleteIcon,
  FolderIcon,
  DownloadFile,
  DownLoadIcon,
  FileMangeIcon,
  ManageRightMenu,
} from "../../Assets/Assets";
import {
  copy,
  convertDate,
  formatString,
  downloadMedia,
  Foldercolumns,
  getItemsfolder,
  extractFileName,
  // validateAddress,
  formatStorageSize,
  ListViewContent,
  GridViewContent,
  validateEmailOrAddress,
} from "../../Utils/helpers";
import {
  deleteFiles,
  createFolder,
  folderDelete,
  updateFolder,
  fetchNestedFolders,
  fetchContainerFolderMedia,
} from "../../store/slices/FolderSlice";
import {
  API_URL,
  ERR_MSG,
  OPERATIONS,
  HTTP_STATUS_CODE,
  FILE_UPLOAD_STATUS,
  INFINITE_SCROLLBAR,
} from "../../constants";
import {
  shareItem,
  removeMember,
  unSharePublicly,
  fetchSharedMedia,
  fetchIsPubliclyShared,
  sendInvitation,
} from "../../store/slices/shareSlice";
import Fileimg from "../../Assets/images/fileimg.png";
import "./FileManagement.scss";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import TableWrapper from "./tableWrapper";
import { env } from "../../constants/env";
import { EarthIcon } from "../../Assets/Assets";
import video from "../../Assets/images/video.png";
import audio from "../../Assets/images/audio.png";
import { AppContext } from "../../store/Context";
import { LoadingOutlined } from "@ant-design/icons";
import fileImg from "../../Assets/images/file.png";
import useLogout from "../../CustomHooks/useLogout";
import useKeyPress from "../../Utils/validationUtils";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../CustomHooks/useDebounce";
import notFound from "../../Assets/images/notfound.svg";
import fileIconsvg from "../../Assets/images/fileIcon.svg";
import fileIcon from "../../Assets/images/filupload.png";
import videoIcon from "../../Assets/images/videoIcon.svg";
import audioIcon from "../../Assets/images/audioIcon.svg";
import folderIcons from "../../Assets/images/folderIcon.png";
import { capitalizeFirstLetter } from "../../Utils/helpers";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommonModal from "../../Common/CommonModal/CommonModal";
import { addToBreadcrumb } from "../../store/slices/breadcrumbSlice";
import { React, useEffect, useState, useRef, useContext } from "react";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import MediaDisplay from "../../Common/Components/MediaDisplay/MediaDisplay";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import CustomBreadcrumb from "../../Common/Components/CustomBreadcrumb/CustomBreadcrumb";
import {
  fetchUserGuide,
  setIsMemberUserGuide,
} from "../../store/slices/userGuideSlice";
import Queue from "../../Utils/eventEmitter";
import Shildicon from "../../Assets/images/shildicon.png";
import Lockbgtransparent from "../../Assets/images/lockbgtransparent.png";

function FolderManage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const isLoading = useRef(false);
  const { id, type } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [folderIdToEdit, setFolderIdToEdit] = useState("");
  const [isBtnDisable, setDisable] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShareModalVisible, setShareIsModalVisible] = useState(false);
  const [isDetailModal, setIsDetailModal] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [isGridView, setGridView] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFolderDetails, setSelectedFolderDetails] = useState(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 2000);
  const [isRemoveVisible, setisRemoveVisible] = useState(false);
  const [selectedId, setSelectedContainerId] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [isDeleteBtnDisable, setDeleteBtnDisable] = useState(false);
  const [isGeneratePublicLink, setisGeneratePublicLink] = useState(true);
  const [folderError, setFolderError] = useState("");
  const {
    uploadFileQueue,
    setUploadFileQueue,
    updateFileStatus,
    setUploadFileQueueDuplicate,
    uploadFileQueueDulicate,
  } = useContext(AppContext);
  const folderChain = useSelector((state) => state.breadcrumb.folderChain);
  const firstFolderChain = folderChain?.[0] || null;
  const { folders, isMoreData, status, dataLoding } = useSelector(
    (state) => state.folders,
  );
  const fileInputRef = useRef(null);
  const [wltErr, setWltErr] = useState("");
  const { csrfToken, isMemberGuide } = useSelector((state) => state.auth);
  const auth = useSelector((state) => state.auth);
  const container_id = firstFolderChain?.id;
  const parentsID = container_id ? container_id.match(/[^/]+$/)[0] : null;
  const [sharedMembersWrtMedia, setSharedMembersWrtMedia] = useState(null);
  const [sharedMembersLoading, setSharedMembersLoading] = useState(false);
  let page = useRef(INFINITE_SCROLLBAR.DEFAULT_PAGE);
  let limit = useRef(INFINITE_SCROLLBAR.DEFAULT_LIMIT);
  const [isWrapperHeight, setIsWrapperHeight] = useState(true);
  let sharedMembersPage = useRef(INFINITE_SCROLLBAR.DEFAULT_PAGE);
  let sharedMembersLimit = useRef(INFINITE_SCROLLBAR.DEFAULT_LIMIT);
  const [isMoreSharedMembers, setIsMoreSharedMembers] = useState(false);
  const [sharedMediaId, setSharedMediaId] = useState(null);
  const [isPubliclyShared, setIsPubliclyShared] = useState(false);
  const [unshareId, setUnshareId] = useState(null);
  const [mediaToShare, setMediaToShare] = useState(null);
  const [shareableType, setShareableType] = useState(null);
  const [accessLevel, setAccessLevel] = useState("read");
  const [accessType, setAccessType] = useState("private");
  const [walletAddress, setWalletAddress] = useState("");
  const [isShareUnshareBtnDisable, setShareUnshareBtnDisable] = useState(true);
  const [downloadingId, setDownloadingId] = useState(null);
  const [dropDownItemType, setDropDownItemType] = useState("");
  const [isSharedLoading, setIsSharedLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const { isMemberGuideStore } = useSelector((state) => state.userGuide);
  const { planType, email } = useSelector((state) => state.user);
  const queue = new Queue(updateFileStatus, dispatch, csrfToken);
  const [isModalOpene, setIsModalOpene] = useState(false);
  const [fileSeleted, setFileSelected] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [encryptionModalVisible, setEncryptionModalVisible] = useState(false);
  const [encryptionModalFolderVisible, setEncryptionModalFolderVisible] =
    useState(false);
  const [folderDatas, setFolderData] = useState(null);
  const [isEncrypted, setIsEncrypted] = useState(false);
  const [invitationModalVisible, setInvitationModalVisible] = useState(false);
  const [invitationEmail, setInvitationEmail] = useState("");
  const [downloadIdEncryption, setDownloadingIdEncrytion] = useState("");
  const [downloadFormat, setdownloadFormat] = useState("");

  useEffect(() => {
    if (status === "loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (!walletAddress) {
      setShareUnshareBtnDisable(true);
    } else {
      setShareUnshareBtnDisable(false);
    }
    if (folderError || !folderName || folderName.length < 3 || wltErr) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    folderName,
    folderError,
    wltErr,
    walletAddress,
    status,
    dispatch,
    searchTerm,
  ]);

  queue.on("uploadSuccess", (id, newStatus) => {
    updateFileStatus(id, newStatus);
  });
  queue.on("uploadFail", (id, newStatus) => {
    updateFileStatus(id, newStatus);
  });
  queue.on("processing", (id, newStatus) => {
    updateFileStatus(id, newStatus);
  });
  queue.on("queueEmpty", () => {
    setUploadFileQueue([]);
  });

  useEffect(() => {
    page.current = INFINITE_SCROLLBAR.DEFAULT_PAGE;
    limit.current = INFINITE_SCROLLBAR.DEFAULT_LIMIT;

    resetScrollBar();
    if (type === "container") {
      dispatch(
        fetchContainerFolderMedia({
          id,
          handleLogOut,
          page: page.current,
          limit: limit.current,
          searchTerm: debouncedSearchTerm,
          prevData: null,
          csrfToken,
        }),
      );
    } else if (type === "folder") {
      dispatch(
        fetchNestedFolders({
          id,
          handleLogOut,
          page: page.current,
          limit: limit.current,
          searchTerm: debouncedSearchTerm,
          prevData: null,
          csrfToken,
          parentsID,
        }),
      );
    }
  }, [dispatch, id, debouncedSearchTerm, type]);

  useEffect(() => {
    if (mediaToShare) {
      getIsPubliclyShared();
    }
  }, [mediaToShare]);

  useEffect(() => {
    if (folders) {
      const tableData = folders.map((media, index) => ({
        ...media,
        key: media.id,
        name: media.name,
        folderName: [
          <span key={media?.name} className="container-ellipsis">
            <span key={`uploadWrapper${index + 1}`}>
              {extractFileName(media?.format) === "-" ? (
                <FolderImg />
              ) : (
                <>
                  <img
                    width="50px"
                    src={
                      media.is_encrypted
                        ? Shildicon
                        : getImage(
                            media?.format,
                            media?.ipfs_url,
                            false,
                            media?.id,
                            media.is_encrypted,
                          )
                    }
                  />
                </>
              )}
              <span>
                {media?.name.length < 20 ? (
                  media?.name
                ) : (
                  <Tooltip
                    key={`upload${index + 1}`}
                    placement="topLeft"
                    title={media?.name}
                  >
                    <span>{formatString(media?.name, 6, 6)}</span>
                  </Tooltip>
                )}
              </span>
            </span>
          </span>,
        ],
        format: media?.format,
        storage: formatStorageSize(media?.storage ?? media?.size) || "0",
        dateUpdated: convertDate(media.updated_at) || "0",
        blankIcon: (
          <Dropdown
            menu={{
              items: getItemsfolder(
                media,
                handleOperations,
                firstFolderChain,
                OPERATIONS,
              ),
            }}
            trigger={["click"]}
          >
            <a className="threeDot" onClick={(e) => e.preventDefault()}>
              <ThreeDots />
            </a>
          </Dropdown>
        ),
      }));
      setData(tableData);
    }
  }, [folders]);

  useEffect(() => {
    async function onScroll() {
      try {
        if (
          tableBody &&
          tableBody.scrollHeight -
            (tableBody.scrollTop + tableBody.clientHeight) <=
            2 &&
          isMoreData &&
          !isLoading.current
        ) {
          isLoading.current = true;
          const loader = document.createElement("div");
          loader.setAttribute("class", "loader");
          tableBody.appendChild(loader);
          page.current += 1;

          let resultAction;
          if (type === "container") {
            resultAction = await dispatch(
              fetchContainerFolderMedia({
                id,
                handleLogOut,
                page: page.current,
                limit: limit.current,
                searchTerm: debouncedSearchTerm,
                prevData: folders,
                csrfToken,
              }),
            ).unwrap();
          } else {
            resultAction = await dispatch(
              fetchNestedFolders({
                id,
                handleLogOut,
                page: page.current,
                limit: limit.current,
                searchTerm: debouncedSearchTerm,
                prevData: folders,
                csrfToken,
                parentsID,
              }),
            ).unwrap();
          }

          if (resultAction) {
            isLoading.current = false;
            tableBody.removeChild(loader);
          }
        }
      } catch (error) {
        console.log("Error while scrolling : ", error);
      }
    }

    const insideContainer = document.querySelector("#insideContainer");
    const elem = insideContainer?.firstChild;
    const tableBody = elem?.lastChild;
    if (!isGridView && tableBody) {
      tableBody.addEventListener("scroll", onScroll, { passive: true });
      return () => {
        tableBody.removeEventListener("scroll", onScroll);
      };
    }
  }, [isMoreData, folders, isLoading.current, type, isGridView, loading]);

  const fetchMoreData = async () => {
    page.current += 1;
    if (type === "container" && isMoreData) {
      dispatch(
        fetchContainerFolderMedia({
          id,
          handleLogOut,
          page: page.current,
          limit: limit.current,
          searchTerm: debouncedSearchTerm,
          prevData: folders,
          csrfToken,
        }),
      );
    } else if (type === "folder") {
      dispatch(
        fetchNestedFolders({
          id,
          handleLogOut,
          page: page.current,
          limit: limit.current,
          searchTerm: debouncedSearchTerm,
          prevData: folders,
          csrfToken,
          parentsID,
        }),
      );
    }
  };

  const handleFolderCreation = async () => {
    try {
      if (!folderName) {
        setFolderError("Folder name is required");
        return;
      }
      let resultAction;
      if (isProcessing === false && isBtnDisable === false) {
        setFolderError("");
        setIsProcessing(true);
        setDisable(true);

        if (editModal && !loading) {
          resultAction = await dispatch(
            updateFolder({
              type,
              folderId: folderIdToEdit,
              newFolderName: folderName,
              id,
              handleLogOut,
              csrfToken,
            }),
          ).unwrap();
        } else {
          const firstFolder =
            folderChain.length > 0 ? folderChain[0]?.id : null;
          const parentID = firstFolder?.split("/").pop();
          const actionPayload = {
            folderName,
            handleLogOut,
            type,
            id,
            csrfToken,
            ...(type !== "container" && { parentID }),
          };

          resultAction = await dispatch(createFolder(actionPayload)).unwrap();
        }

        if (resultAction?.code === HTTP_STATUS_CODE.OK) {
          toast.success(
            resultAction?.message ||
              (editModal
                ? "Update Folder Success"
                : "Successfully created Folder"),
          );
        } else {
          console.log(resultAction?.error?.message || "Error creating");
        }
      }
    } catch (error) {
      console.log("Error creating folder: " + error);
    } finally {
      closeModal();
    }
  };

  useKeyPress(
    "Enter",
    () => {
      if (!loading && isModalVisible && isProcessing === false) {
        handleFolderCreation();
      }
    },
    [loading, handleFolderCreation, isProcessing],
  );

  const handleFolderNavigation = async (record) => {
    const key = record?.key || record?.id;

    if (!record?.format) {
      dispatch(
        addToBreadcrumb({
          id: `/folder-management/folder/${key}`,
          name: record?.name,
        }),
      );
      navigate(`/folder-management/folder/${key}`);
      return;
    } else {
      if (record?.format === "image/svg+xml") {
        handleDownload(key, record?.name);
        return;
      } else {
        if (
          record?.is_encrypted &&
          record?.format !== "video/mp4" &&
          record?.format !== "image/jpeg" &&
          record?.format !== "image/png" &&
          record?.format != "audio/mpeg" &&
          record?.format != "audio/mp3"
        ) {
          setDownloadingIdEncrytion(record?.id);
          setdownloadFormat(record?.format);
          setIsModalOpene(true);
        } else {
          setIsModalOpen(true);
          setSelectedFile(record);
        }
      }
    }
  };

  const handleToggleView = (key) => {
    key == 2 ? setGridView(true) : setGridView(false);
  };

  const handleAddFolder = () => {
    setDisable(true);
    setEditModal(false);
    setFolderName("");
    setIsModalVisible(true);
  };

  const handleChange = (e) => {
    const value = e.target.value.trimStart().replace(/\s{2,}/g, " ");

    setFolderName(value);
    if (value.length > 0 && value.length < 3) {
      setFolderError(ERR_MSG.FOLDER_MESSAGE);
    } else {
      setFolderError("");
    }
  };

  const handleSearchChange = (e) => {
    if (e && e.target) {
      setSearchTerm(e.target.value);
    } else {
      console.error("Event or target is undefined");
    }
  };

  const handleChangeShare = (field, value) => {
    switch (field) {
      case "walletAddress": {
        setWalletAddress(value.trim());
        const checkRes = validateEmailOrAddress(
          value,
          auth.walletAddress,
          email,
        );
        // if (checkRes?.valid) {
        //   setSharableInputType(checkRes?.type);
        // }
        setWltErr(checkRes?.error);

        break;
      }
      case "accessLevel":
        setAccessLevel(value);
        break;
      case "accessType":
        setAccessType(value);
        break;
      default:
        break;
    }
  };

  const getIsPubliclyShared = async () => {
    try {
      setIsSharedLoading(true);
      const response = await dispatch(
        fetchIsPubliclyShared({
          shareable_id: sharedMediaId ? sharedMediaId : mediaToShare?.id,
          handleLogOut,
          csrfToken,
        }),
      ).unwrap();
      setIsPubliclyShared(response?.isPublic);
    } catch (error) {
      console.error("An unknown error", error);

      toast.error(error);
    } finally {
      setIsSharedLoading(false);
    }
  };

  const fetchSharedMembers = async (shareable_id) => {
    setSharedMediaId(shareable_id);
    setSharedMembersLoading(true);
    const response = await dispatch(
      fetchSharedMedia({
        shareable_id,
        page: INFINITE_SCROLLBAR.DEFAULT_PAGE,
        limit: INFINITE_SCROLLBAR.DEFAULT_LIMIT,
        prevData: null,
        handleLogOut,
        csrfToken,
      }),
    ).unwrap();
    setSharedMembersLoading(false);

    setIsMoreSharedMembers(response?.isMoreSharedMembers);
    setSharedMembersWrtMedia(response?.data);
    setIsWrapperHeight(response?.data?.length <= 2 ? false : true);
  };

  const fetchSharedMembersMore = async () => {
    sharedMembersPage.current += 1;
    const response = await dispatch(
      fetchSharedMedia({
        shareable_id: sharedMediaId,
        page: sharedMembersPage.current,
        limit: sharedMembersLimit.current,
        prevData: sharedMembersWrtMedia,
        handleLogOut,
        csrfToken,
      }),
    ).unwrap();

    setSharedMembersWrtMedia(response?.data);
    setIsMoreSharedMembers(response.isMoreSharedMembers);
  };

  const handleOperations = (data, action, type = "") => {
    setSharedMediaId(null);

    switch (action) {
      case OPERATIONS.open:
        handleFolderNavigation(data);
        break;

      case OPERATIONS.edit:
        setEditModal(true);
        setFolderIdToEdit(data?.id);
        setFolderName(data?.name);
        setIsModalVisible(true);
        break;

      case OPERATIONS.details:
        setIsDetailModal(true);
        setDropDownItemType(type);
        setSelectedFolderDetails(data);
        break;

      case OPERATIONS.share:
        setMediaToShare(data);
        setSharedMembersWrtMedia(null);
        sharedMembersPage.current = INFINITE_SCROLLBAR.DEFAULT_PAGE;
        fetchSharedMembers(data?.id);
        setIsEncrypted(data?.is_encrypted);
        setShareIsModalVisible(true);

        if (type === "file") {
          setShareableType("media");
        } else {
          setShareableType(type);
        }

        break;

      case OPERATIONS.delete:
        setisRemoveVisible(true);
        setSelectedContainerId(data?.id);
        if (type === "folder") {
          setDeleteType("folder");
        } else if (type === "file") {
          setDeleteType("file");
        }
        break;
      case OPERATIONS.download:
        handleDownload(data?.id, data?.format, data?.name);

        break;

      default:
        break;
    }
  };

  function resetScrollBar() {
    const insideContainer = document.querySelector("#insideContainer");
    const elem = insideContainer?.firstChild;
    const tableBody = elem?.lastChild;
    if (tableBody) {
      tableBody.scrollTop = 0;
    }
  }

  const handleCheckboxChange = (fileId, isChecked) => {
    setSelectedFiles((prevSelected) =>
      isChecked
        ? [...prevSelected, fileId]
        : prevSelected.filter((id) => id !== fileId),
    );
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allFileIds = fileSeleted.map((file) => file.id);
      setSelectedFiles(allFileIds);
    } else {
      setSelectedFiles([]);
    }
  };
  const isAllSelected =
    fileSeleted.length > 0 && selectedFiles.length === fileSeleted.length;

  const handleConfirm = () => {
    const uploadData = fileSeleted.map((file) => ({
      ...file,
      is_encrypted: selectedFiles.includes(file.id) ? "true" : "false",
    }));

    uploadData &&
      uploadData.forEach((file) => {
        queue.enqueue(file);
      });
    setUploadFileQueueDuplicate([...uploadData, ...uploadFileQueueDulicate]);
    setUploadFileQueue([...uploadData, ...uploadFileQueue]);
    setFileSelected([]);
    setSelectedFiles([]);
    setEncryptionModalVisible(false);
  };

  const folderUpload = (isEncrypted) => {
    const folderData = {
      id: uuidv4(),
      type: "folder",
      container_id: parentsID,
      parent_id: id,
      file: folderDatas,
      is_encrypted: isEncrypted,
      containerName: firstFolderChain?.name,
      status: FILE_UPLOAD_STATUS.IN_QUEUE,
      handleLogOut: handleLogOut,
      file_length: folderDatas?.length,
    };

    queue.enqueue(folderData);
    setUploadFileQueue([folderData, ...uploadFileQueue]);
    fileInputRef.current.value = "";
    setEncryptionModalFolderVisible(false);
  };

  const uploadData = async (event, filesOrFolder) => {
    const files = event.target.files;

    if (filesOrFolder === "files") {
      if (files?.length > 5) {
        toast.error("You can upload up to 5 files at once");
      } else {
        const filesData = Array.from(files).map((file) => ({
          id: uuidv4(),
          type: "file",
          mediable_id: id,
          file: file,
          containerName: firstFolderChain?.name,
          status: FILE_UPLOAD_STATUS.IN_QUEUE,
          handleLogOut,
        }));

        if (planType === "free") {
          let filesData = [];

          for (let file of files) {
            const fileData = {
              id: uuidv4(),
              type: "file",
              mediable_id: id,
              file: file,
              is_encrypted: "false",
              containerName: firstFolderChain?.name,
              status: FILE_UPLOAD_STATUS.IN_QUEUE,
              handleLogOut,
            };
            queue.enqueue(fileData);

            filesData.push(fileData);
          }

          setUploadFileQueueDuplicate([
            ...filesData,
            ...uploadFileQueueDulicate,
          ]);
          setUploadFileQueue([...filesData, ...uploadFileQueue]);
          fileInputRef.current.value = "";
        } else {
          setFileSelected(filesData);
          setEncryptionModalVisible(true);
          fileInputRef.current.value = "";
        }
      }
    } else {
      if (files?.length > 0) {
        if (planType === "free") {
          const folderData = {
            id: uuidv4(),
            type: "folder",
            container_id: parentsID,
            parent_id: id,
            file: files,
            is_encrypted: "false",
            containerName: firstFolderChain?.name,
            status: FILE_UPLOAD_STATUS.IN_QUEUE,
            handleLogOut: handleLogOut,
            file_length: files?.length,
          };
          queue.enqueue(folderData);
          setUploadFileQueue([folderData, ...uploadFileQueue]);
          fileInputRef.current.value = "";
        } else {
          setEncryptionModalFolderVisible(true);
          setFolderData(files);
        }
      }
    }
    fileInputRef.current.value = "";
  };

  const uploadItems = [
    {
      label: (
        <>
          <input
            className="custom-input"
            type="file"
            id="file"
            multiple
            ref={fileInputRef}
            onChange={($event) => uploadData($event, "files")}
          />
          <label htmlFor="file" className="item padding cursor-pointer">
            <FileMangeIcon /> Upload Files
          </label>
        </>
      ),
      key: "0",
    },
    {
      label: (
        <>
          <input
            className="custom-input"
            type="file"
            webkitdirectory="true"
            id="folder"
            multiple
            onChange={($event) => uploadData($event)}
          />
          <label htmlFor="folder" className="item padding cursor-pointer">
            <FolderIcon /> Upload Folder
          </label>
        </>
      ),
      key: "1",
    },
  ];

  const columns = Foldercolumns(handleFolderNavigation);

  const getImage = (format, ipfsUrl, isGridView, mediaId) => {
    let img;
    if (!format) {
      img = folderIcons;
    } else if (format?.includes("image")) {
      if (format === "image/svg+xml") {
        img = isGridView ? fileImg : fileIcon;
      } else {
        img = `${env.ipfsUrl}/${ipfsUrl}/${mediaId}`;
      }
    } else if (format?.includes("video")) {
      img = isGridView ? video : videoIcon;
    } else if (format?.includes("audio")) {
      img = isGridView ? audio : audioIcon;
    } else {
      img = isGridView ? fileImg : fileIconsvg;
    }
    return img;
  };

  const handleDownload = async (id, format, name = "") => {
    try {
      setDownloadingId(id);

      const downloadUrl = `${API_URL.DOWNLOAD_FILES}${id}/download`;
      await downloadMedia(downloadUrl, format, handleLogOut, name);
    } catch (error) {
      console.error("Error downloading the file:", error);
      toast.error("Download failed!");
    } finally {
      setDownloadingId(null);
      closeModal();
    }
  };

  const tabItems = [
    {
      key: "1",
      label: (
        <div className="toggle">
          <span className={`${!isGridView ? "downLoadIcon" : "plusIcon"}`}>
            <ManageMenu />
          </span>
        </div>
      ),
      children: (
        <ListViewContent
          loading={loading}
          dataLoading={dataLoding}
          data={data}
          columns={columns}
        />
      ),
    },
    {
      key: "2",
      label: (
        <div className="toggle">
          <span className={`${isGridView ? "downLoadIcon" : "plusIcon"}`}>
            <ManageRightMenu />
          </span>
        </div>
      ),
      children: (
        <GridViewContent
          loading={loading}
          dataLoading={dataLoding}
          folders={folders}
          data={data}
          fetchMoreData={fetchMoreData}
          isMoreData={isMoreData}
          notFound={notFound}
          handleFolderNavigation={handleFolderNavigation}
          downloadingId={downloadingId}
          handleDownload={handleDownload}
          getItemsfolder={getItemsfolder}
          handleOperations={handleOperations}
          firstFolderChain={firstFolderChain}
          OPERATIONS={OPERATIONS}
          getImage={getImage}
        />
      ),
    },
  ];

  const OperationsSlot = {
    left: (
      <div className="folderManage_top">
        <InputCustom
          customClass="commonSearchInput"
          label
          searchInputs
          placeholder="Search Folder"
          value={searchTerm}
          // onBlur={handleMouseOut}
          onChange={handleSearchChange}
        />

        <div className="fileManagement_heading_btn">
          {["manage", "write", "all"].includes(
            firstFolderChain?.container_access_level,
          ) && (
            <>
              <ButtonCustom
                smallBtn
                label="Upload"
                dropDown
                items={uploadItems}
                trigger={["click"]}
              >
                <DownLoadIcon />
              </ButtonCustom>
              <ButtonCustom
                smallBtn
                label="Add Folder"
                onClick={handleAddFolder}
              >
                <PlusIcon />
              </ButtonCustom>
            </>
          )}
        </div>
      </div>
    ),
  };

  const deleteFolder = async (ids) => {
    try {
      setDeleteBtnDisable(true);
      const response = await dispatch(
        folderDelete({ ids, csrfToken, handleLogOut }),
      );
      if (response.payload?.code === HTTP_STATUS_CODE.OK) {
        toast.success(response?.payload?.message || "Deleted successfully");
      }
    } catch (error) {
      console.log("Error deleting folder: ", error);
    } finally {
      closeModal();
    }
  };

  const deleteFile = async (ids) => {
    try {
      setDeleteBtnDisable(true);
      const response = await dispatch(
        deleteFiles({ ids, csrfToken, handleLogOut }),
      );
      if (response.payload?.code === HTTP_STATUS_CODE.OK) {
        toast.success(response.payload.message || "Deleted successfully");
      }
    } catch (error) {
      console.log("error: ", error);
      toast.error(error.message || "Something went wrong");
    } finally {
      closeModal();
    }
  };

  const handlePrivateUnshare = async (
    member_id,
    shareable_id,
    wallet_address,
  ) => {
    try {
      setUnshareId(wallet_address);
      const res = await dispatch(
        removeMember({ member_id, shareable_id, handleLogOut, csrfToken }),
      );

      if (res?.meta?.requestStatus === "fulfilled") {
        toast.success(res?.payload || "Member removed successfully");
      } else {
        toast.error(
          `Failed to remove member: ${res?.message || "Unexpected error occurred"}`,
        );
      }
    } catch (error) {
      console.error("Error removing member:", error);
      toast.error(
        "An error occurred while removing the member. Please try again.",
      );
    } finally {
      closeModal();
      setUnshareId(null);
    }
  };

  const handleShare = async (access) => {
    try {
      {
        access == "public" && setShareLoading(true);
      }

      let res;
      setShareUnshareBtnDisable(true);
      if (isPubliclyShared && access == "public") {
        res = await dispatch(
          unSharePublicly({
            shareable_id: sharedMediaId,
            handleLogOut,
            csrfToken,
          }),
        ).unwrap();

        if (res?.code === 200) {
          toast.success("Item Unshared successfully!");
        } else {
          toast.error(
            res?.message || "Failed to Unshare item. Please try again.",
          );
        }
      } else {
        res = await dispatch(
          shareItem({
            identifier: walletAddress,
            shareable_id: mediaToShare?.id ?? "",
            shareable_type: shareableType,
            access_level: accessType === "private" ? accessLevel : "read",
            access_type: access == "public" ? access : accessType,
            handleLogOut,
            csrfToken,
          }),
        );

        if (res?.meta?.requestStatus === "fulfilled") {
          toast.success("Item shared successfully!");
        }
        if (
          res?.meta?.requestStatus === "rejected" &&
          res?.payload ===
            "the email address provided for this user does not exist"
        ) {
          setInvitationModalVisible(true);
          setInvitationEmail(walletAddress);
        }
      }

      if (!isMemberGuide && !isMemberGuideStore && accessType == "private") {
        await dispatch(
          fetchUserGuide({
            payload: "is_member_guide",
            handleLogOut,
            csrfToken,
          }),
        );
      }
      dispatch(setIsMemberUserGuide(true));
      // if (access == "public") {
      //   getIsPubliclyShared();
      // }
    } catch (error) {
      console.error("Error occurred during sharing: ", error);
    } finally {
      closeModal();
      {
        access == "public" && setShareLoading(false);
      }
    }
  };

  const closeModal = () => {
    setIsDetailModal(false);
    setIsModalVisible(false);
    setisRemoveVisible(false);

    setFolderName("");
    setFolderError("");
    setWalletAddress("");
    setAccessLevel("read");
    setMediaToShare(null);

    setDisable(false);
    setDeleteBtnDisable(false);
    setShareUnshareBtnDisable(true);
    setisGeneratePublicLink(true);
    setIsProcessing(false);
    setLoading(false);
    setShareIsModalVisible(false);
    setWltErr("");
    setShareLoading(false);
    page.current = INFINITE_SCROLLBAR.DEFAULT_PAGE;
    resetScrollBar();
    setIsModalOpene(false);
  };

  const handleSendInvitations = () => {
    dispatch(
      sendInvitation({
        email: invitationEmail,
        handleLogOut,
      }),
    )
      .unwrap()
      .then(() => {
        setInvitationModalVisible(false);
        setInvitationEmail("");
      })
      .catch((error) => {
        console.error("Error sending invitation:", error);
        setInvitationModalVisible(false);
        setInvitationEmail("");
      });
  };

  return (
    <div className="folderManage">
      <div className="folderManage_breadCrumb">
        {window.innerWidth <= 776 ? (
          ""
        ) : (
          <Link to={"/file-management"}>File Management /</Link>
        )}

        <CustomBreadcrumb
          items={
            Array.isArray(folderChain)
              ? folderChain.map((folder) => ({
                  title: folder.name,
                  href: folder.id,
                }))
              : []
          }
        />
      </div>

      <Tabs
        className="fileManageTabs"
        defaultActiveKey="1"
        onTabClick={(key) => handleToggleView(key)}
        items={tabItems}
        tabBarExtraContent={OperationsSlot}
      />

      {ismodalOpen && (
        <MediaDisplay
          selectedMedia={selectedFile}
          setModalOpen={setIsModalOpen}
        />
      )}

      <CommonModal
        visible={isModalVisible}
        className="newContainerModal"
        title={editModal ? "Rename Folder" : "Create Folder"}
        BtnText={
          isProcessing ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            "Continue"
          )
        }
        customBtutton
        paragraph={
          editModal
            ? "You can make the necessary changes to your folder below. Once done, click 'Continue' to save."
            : "Create your new folder and streamline your file management! Type in the desired folder name below to get started on organizing your digital space."
        }
        icon={<FolderImg />}
        onClick={handleFolderCreation}
        btnDisabled={isBtnDisable || loading}
        content={
          <InputCustom
            regularInput
            placeholder="Enter Here"
            value={folderName}
            label
            required
            onChange={handleChange}
            error={folderError}
            minLength={3}
            maxLength={50}
            onClearError={() => setFolderError("")}
            labletext="Name"
          />
        }
        onOk={closeModal}
        onCancel={closeModal}
      />

      <CommonModal
        className="containerModal"
        visible={isDetailModal}
        title={`${dropDownItemType} Details`}
        BtnText="Continue"
        icon={<FolderImg />}
        content={
          <div>
            <div className="containerModal_bottom">
              <div>
                <p>Name</p> <h4>{selectedFolderDetails?.name}</h4>
              </div>
              {dropDownItemType == "Folder" && (
                <div>
                  <p>Files </p> <h4>{selectedFolderDetails?.media_count} </h4>
                </div>
              )}
              <div>
                <p>Size</p>{" "}
                <h4>
                  {formatStorageSize(
                    selectedFolderDetails?.storage ??
                      selectedFolderDetails?.size,
                  )}
                </h4>
              </div>
              <div>
                <p>Date Created</p>{" "}
                <h4>{convertDate(selectedFolderDetails?.created_at)}</h4>
              </div>
              <div>
                <p>Last Updated </p>{" "}
                <h4>{convertDate(selectedFolderDetails?.updated_at)}</h4>
              </div>
            </div>
          </div>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />

      <CommonModal
        className="share-container2"
        visible={isShareModalVisible}
        title={(shareableType === "folder" ? "Folder" : "File") + " Share"}
        icon={<ShareIcon />}
        onClick={handleShare}
        btnDisabled={isShareUnshareBtnDisable}
        content={
          <>
            {shareableType !== "folder" ? (
              <>
                {/* Public Sharing Section */}
                <div>
                  <label>Public Sharing</label>
                  <div className="publicSec">
                    <div className="publicSec_left">
                      {isGeneratePublicLink && !isPubliclyShared ? (
                        "Click on button to generate link"
                      ) : (
                        <>
                          <Tooltip
                            title={`${env.frontendUrl}/file?ipfsId=${mediaToShare?.ipfs_url}&mediaId=${mediaToShare?.id}`}
                          >
                            <p>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${env.frontendUrl}/file?ipfsId=${mediaToShare?.ipfs_url}&mediaId=${mediaToShare?.id}`}
                              >
                                {`${env.frontendUrl}/file?ipfsId=${mediaToShare?.ipfs_url}&mediaId=${mediaToShare?.id}`}
                              </a>
                            </p>
                          </Tooltip>
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              copy(
                                `${env.frontendUrl}/file?ipfsId=${mediaToShare?.ipfs_url}&mediaId=${mediaToShare?.id}`,
                              );
                            }}
                          >
                            <CopyIcon />
                          </span>
                        </>
                      )}
                    </div>
                    {isGeneratePublicLink && !isPubliclyShared ? (
                      <ButtonCustom
                        regularBtn
                        label="Generate Link"
                        onClick={() => setisGeneratePublicLink(false)}
                        className={
                          isEncrypted || isSharedLoading || shareLoading
                            ? "disableBtn"
                            : ""
                        }
                        disabled={
                          isSharedLoading || shareLoading || isEncrypted
                        }
                      />
                    ) : isPubliclyShared ? (
                      <ButtonCustom
                        regularBtn
                        label={
                          shareLoading ? (
                            <Spin indicator={<LoadingOutlined spin />} />
                          ) : (
                            "Unshare"
                          )
                        }
                        onClick={() => {
                          handleShare("public");
                        }}
                        className={
                          isSharedLoading || shareLoading ? "disableBtn" : ""
                        }
                        disabled={isSharedLoading || shareLoading}
                      />
                    ) : (
                      <ButtonCustom
                        regularBtn
                        label={
                          shareLoading ? (
                            <Spin indicator={<LoadingOutlined spin />} />
                          ) : (
                            "Share"
                          )
                        }
                        onClick={() => {
                          handleShare("public");
                        }}
                        className={
                          isSharedLoading || shareLoading ? "disableBtn" : ""
                        }
                        disabled={isSharedLoading || shareLoading}
                      />
                    )}
                  </div>
                </div>
              </>
            ) : null}

            {/* Private Sharing Section */}
            <div className="share-container2_top2">
              <InputCustom
                label
                required
                regularInput
                minLength={2}
                maxLength={1000}
                placeholder="Enter Wallet Address or Email Here"
                error={wltErr}
                value={walletAddress}
                onChange={(e) =>
                  handleChangeShare("walletAddress", e?.target?.value)
                }
                labletext="Private Sharing"
              />
              <ButtonCustom
                regularBtn
                label="Share Privately"
                onClick={handleShare}
                className={`shareWith_btn ${isShareUnshareBtnDisable || wltErr ? "disableBtn" : ""}`}
                disabled={isShareUnshareBtnDisable || wltErr}
              />
            </div>

            {/* Shared With Section */}
            {sharedMembersLoading && (
              <div
                className="tableSkeleton margin-top"
                data-testid="skeleton-container"
              >
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
            )}

            {sharedMembersWrtMedia?.length > 0 && (
              <div className="shareWith" id="scroll-target">
                <h4>Shared With</h4>
                <TableWrapper
                  lengthData={sharedMembersWrtMedia.length}
                  functionNext={fetchSharedMembersMore}
                  hasMore={isMoreSharedMembers}
                  isWrapperHeight={isWrapperHeight}
                >
                  <div className="shareWith_container">
                    {sharedMembersWrtMedia?.map((media, index) => {
                      return (
                        <div className="shareWith_container_inner" key={index}>
                          <div>
                            <EarthIcon />{" "}
                            <p>
                              <Tooltip
                                key={`upload${index + 1}`}
                                placement="topLeft"
                                title={media?.wallet_address}
                              >
                                {formatString(media?.wallet_address, 15, 15)}
                              </Tooltip>
                            </p>
                          </div>
                          <ButtonCustom
                            regularBtn
                            label="Unshare"
                            className={`shareWith_btn ${unshareId == media?.wallet_address ? "disableBtn" : ""}`}
                            disabled={unshareId == media?.wallet_address}
                            id={media?.wallet_address}
                            onClick={() =>
                              handlePrivateUnshare(
                                media?.grantee_user_id,
                                media?.shareable_id,
                                media?.wallet_address,
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </TableWrapper>
              </div>
            )}
          </>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />

      <CommonModal
        className="confirmationRemoveModal"
        visible={isRemoveVisible}
        BtnText="Continue"
        icon={<DeleteIcon />}
        title={`Delete ${deleteType ? capitalizeFirstLetter(deleteType) : ""}`}
        content={
          <div className="confirmationRemoveModal_inner">
            <h4>Are you sure you want to delete this {deleteType}.</h4>
            <div className="confirmationRemoveModal_btns">
              <ButtonCustom
                onClick={() => setisRemoveVisible(false)}
                borderBtn
                className={isDeleteBtnDisable ? "disableBtn" : ""}
                disabled={isDeleteBtnDisable}
                label="Cancel"
              />

              <ButtonCustom
                onClick={() => {
                  if (deleteType === "folder") {
                    deleteFolder(selectedId);
                  } else if (deleteType === "file") {
                    deleteFile(selectedId);
                  }
                }}
                regularBtn
                className={isDeleteBtnDisable ? "disableBtn" : ""}
                disabled={isDeleteBtnDisable}
                label={
                  isDeleteBtnDisable ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    "Delete"
                  )
                }
              />
            </div>
          </div>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />

      <CommonModal
        className="confirmationRemoveModal"
        visible={encryptionModalVisible}
        BtnText="Continue"
        icon={<img src={Lockbgtransparent} />}
        title="Encryption Modal"
        content={
          <div className="confirmationRemoveModal_inner">
            <h4>Select files you want to encrypt.</h4>
            <div className="file-list">
              <div className="selectbox">
                {/* <Checkbox onChange={() => handleCheckboxChange()}>
                  Select All
                </Checkbox> */}
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={selectedFiles.length > 0 && !isAllSelected}
                  onChange={(e) => handleSelectAll(e.target.checked)}
                >
                  Select All
                </Checkbox>
              </div>

              {fileSeleted.map((file) => (
                <div key={file.id} className="file-item filesytmmodal">
                  <Checkbox
                    checked={selectedFiles.includes(file.id)}
                    onChange={(e) =>
                      handleCheckboxChange(file.id, e.target.checked)
                    }
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <img src={Imgicon} style={{ margiRight: "10px" }} /> */}
                      <img src={Fileimg} style={{ marginRight: "7px" }} />
                      <p> {file?.file?.name} </p>
                    </div>
                  </Checkbox>
                </div>
              ))}
            </div>
            <div className="confirmationRemoveModal_btns">
              <ButtonCustom
                onClick={() => setEncryptionModalVisible(false)}
                borderBtn
                label="Cancel"
              />
              <ButtonCustom
                regularBtn
                onClick={handleConfirm}
                label="Continue"
              />
            </div>
          </div>
        }
        onOk={closeModal}
        onCancel={() => setEncryptionModalVisible(false)}
      />

      <CommonModal
        className="confirmationRemoveModal"
        visible={encryptionModalFolderVisible}
        BtnText="Continue"
        icon={<img src={Lockbgtransparent} />}
        title="Encryption Modal"
        content={
          <div className="confirmationRemoveModal_inner">
            <h4>Do you want to encrypt this Folder ?</h4>
            <div className="file-list"></div>
            <div className="confirmationRemoveModal_btns">
              <ButtonCustom
                onClick={() => folderUpload(false)}
                borderBtn
                label="No"
              />
              <ButtonCustom
                onClick={() => folderUpload(true)}
                regularBtn
                label="Yes"
              />
            </div>
          </div>
        }
        onOk={closeModal}
        onCancel={() => setEncryptionModalFolderVisible(false)}
      />
      <Modal
        className="modal_lock"
        open={isModalOpene}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <div className="modal_main">
          <div className="imgdive">
            <img src={Shildicon} alt="Shield Icon" />
          </div>
          <h4>This file is encrypted</h4>
          <p>To access the file, you must first download it.</p>
          <button
            className="customButton customButton-smallBtn modalbtn"
            id="create-file-button"
            onClick={() => handleDownload(downloadIdEncryption, downloadFormat)}
          >
            <p>Download</p>
            <span className="customButton-icon download">
              <DownloadFile />
            </span>
          </button>
        </div>
      </Modal>

      <CommonModal
        className="invitationModal"
        visible={invitationModalVisible}
        BtnText="Send Invitation"
        icon={<ShareIcon />}
        title={`Invite Users to ${shareableType}`}
        content={
          <div className="invitationModal_inner">
            <h4>We couldn&apos;t associated with this email address:</h4>
            <div className="email-list">
              <p>
                <strong>{invitationEmail}</strong>
              </p>
            </div>
            <p>
              Please ensure the email address is correct or invite them using
              the form below.
            </p>

            <div className="invitationModal_btns confirmationRemoveModal_btns">
              <ButtonCustom
                onClick={() => setInvitationModalVisible(false)}
                borderBtn
                label="Cancel"
              />
              <ButtonCustom
                regularBtn
                onClick={handleSendInvitations}
                label="Send Invitation"
              />
            </div>
          </div>
        }
        onOk={() => setInvitationModalVisible(false)}
        onCancel={() => setInvitationModalVisible(false)}
      />
    </div>
  );
}

export default FolderManage;
