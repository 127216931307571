import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: null,
  isLoggedIn: false,
  walletAddress: null,
  connectedToWallet: false,
  isNew: null,
  csrfToken: null,
  isDashboardGuide: null,
  isFileGuide: null,
  isMemberGuide: null,
};

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    logIn: (state) => {
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.name = null;
      localStorage.removeItem("dashboardGuide");
      localStorage.removeItem("fileManagementGuide");
      localStorage.removeItem("addMemberGuide");
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setCsrfToken: (state, action) => {
      state.csrfToken = action.payload;
    },
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
      return;
    },
    setIsNew: (state, action) => {
      state.isNew = action.payload;
      return;
    },
    setWalletConnection: (state, action) => {
      state.walletAddress = action.payload;
      return;
    },
    setDashboardGuide: (state, action) => {
      state.isDashboardGuide = action.payload;
      return;
    },
    setFileManagementGuide: (state, action) => {
      state.isFileGuide = action.payload;
      return;
    },
    setMemberGuide: (state, action) => {
      state.isMemberGuide = action.payload;
      return;
    },
  },
});

export const getName = (state) => state.auth.name;

export const getIsNew = (state) => state.auth.isNew;

export const {
  logIn,
  logOut,
  setWalletAddress,
  walletAddress,
  setName,
  setCsrfToken,
  setIsNew,
  setDashboardGuide,
  setFileManagementGuide,
  setMemberGuide,
} = authSlice.actions;

export default authSlice.reducer;
