import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Skeleton, Switch, Table } from "antd";
import NoData from "../../Assets/images/notfound.svg";
import {
  createNewApiKey,
  fetchApiKeys,
  updateApiKey,
} from "../../store/slices/apikeySlice";
import useLogout from "../../CustomHooks/useLogout";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import { PlusIcon } from "../../Assets/Assets";
import CreateNewApiModal from "./CreateNewApiModal";
import toast from "react-hot-toast";
import { formatIsoDate } from "../../Utils/helpers";
import { Path } from "../../Routing/Constant/RoutePaths";
import { useNavigate } from "react-router-dom";

// API Key Table Component
function ApiKeyTable() {
  const dispatch = useDispatch();
  const { apiKeys, loading } = useSelector((state) => state.apikey);
  const { csrfToken } = useSelector((state) => state.auth);
  const handleLogOut = useLogout();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [generatedData, setGeneratedData] = useState({}); // Track the generated API key
  const { planType } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // const [isActive, setIsActive] = useState(false);

  // const handleToggle = () => {
  //   setIsActive(prevState => !prevState);
  // };

  useEffect(() => {
    if (planType === "free") {
      navigate(Path.DASHBOARD);
    }
  }, []);
  useEffect(() => {
    if (!apiKeys.length) {
      dispatch(fetchApiKeys({ csrfToken, handleLogOut }));
    }
  }, [dispatch, apiKeys.length]);

  const handleCreateApiKey = async () => {
    try {
      setIsCreating(true);
      const res = await dispatch(
        createNewApiKey({ csrfToken, handleLogOut }),
      ).unwrap();
      if (res) {
        setIsModalVisible(true);
        setGeneratedData(res);
        // toast.success("API Key Created Successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to create API key");
    } finally {
      setIsCreating(false);
    }
  };

  const handleStatusChange = async (k_id, status) => {
    try {
      await dispatch(
        updateApiKey({ k_id, status, csrfToken, handleLogOut }),
      ).unwrap();
      toast.success("Status updated successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to update status");
    }
  };

  const columns = [
    {
      title: "API Key",
      dataIndex: "k_id",
      key: "k_id",
      render: (_, record) => {
        return record?.k_id ? record?.k_id : record?.access_key || "N/A";
      },
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => formatIsoDate(date),
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date) => formatIsoDate(date),
      sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
      render: (state) => (state === "active" ? "Active" : "Inactive"),
      sorter: (a, b) => a.state.localeCompare(b.state),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Switch
          checked={record?.state === "active"}
          onChange={(checked) =>
            handleStatusChange(
              record?.k_id ?? record?.access_key,
              checked ? "active" : "inactive",
            )
          }
        />
      ),
    },
  ];

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h3>API Key Generation</h3>
      <div className="leftbutton">
        <ButtonCustom
          regularBtn
          disabled={isCreating}
          className={isCreating ? "disableBtn" : ""}
          onClick={handleCreateApiKey}
          label="Create New API Key"
          id="create-member-button"
        >
          <PlusIcon />
        </ButtonCustom>
      </div>

      {loading ? (
        <Skeleton active paragraph={{ rows: 5 }} />
      ) : (
        <Table
          columns={columns}
          dataSource={apiKeys && apiKeys}
          className="commontable"
          // scroll={{ y: 550, x: "max-content" }}
          locale={{
            emptyText: (
              <Empty
                image={<img src={NoData} alt="No data found" />}
                description="No Data Found"
              />
            ),
          }}
          pagination={false}
          rowKey="k_id"
          showSorterTooltip={{
            target: "sorter-icon",
          }}
        />
      )}
      <CreateNewApiModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        generatedData={generatedData} // Pass generatedData to modal
        handleClick={handleCreateApiKey}
      />
    </div>
  );
}

export default ApiKeyTable;
