import {
  capitalizeFirstLetter,
  roundToTwoDecimalPlaces,
} from "../../Utils/helpers";
import {
  setPlanId,
  setPaymentProcessModal,
} from "../../store/slices/userSlice";
import "./AccountType.scss";
import { Spin } from "antd";
import { Switch } from "antd";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { env } from "../../constants/env";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import httpRequest from "../../Utils/httpsRequest";
import { LoadingOutlined } from "@ant-design/icons";
import useLogout from "../../CustomHooks/useLogout";
import { useDispatch, useSelector } from "react-redux";
import faitIcon from "../../Assets/images/dollarIocn.png";
import { Path } from "../../Routing/Constant/RoutePaths";
import { fetchPlans } from "../../store/slices/planSlice";
import TomiToken from "../../Assets/images/TomiToken.svg";
import ERC20Contract from "../../helper/erc20.connection";
import { setPlanType } from "../../store/slices/userSlice";
import { setNextPlan } from "../../store/slices/userSlice";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { ProAccountIcon, ScaleIcon } from "../../Assets/Assets";
import AccountCard from "../../Common/Cards/AccountCard/AccountCard";
import { formatStorageSize, formatNumber } from "../../Utils/helpers";
import SubscriptionContract from "../../helper/subscription.connection";
import { HTTP_METHOD, API_URL, HTTP_STATUS_CODE } from "../../constants";

function AccountTypeDashboard() {
  const perMonth = " per month";
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const navigate = useNavigate();
  const [decimals, setDecimals] = useState(0);
  const [isLoading, setLoading] = useState("");
  const [userBalance, setUserBalance] = useState(0);
  const { walletProvider } = useWeb3ModalProvider();
  const [capValueUsdt, setCapValueUsdt] = useState(0);
  const [capValueTomi, setCapValueTomi] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [planExpired, setPlanExpired] = useState(false);
  const { plans, loading } = useSelector((state) => state.plan);
  const [tomiTokenAddress, setTomiTokenAddress] = useState(null);
  const [usdtTokenAddress, setUsdtTokenAddress] = useState(null);
  const [tomiTokenSelected, setTomiTokenSelected] = useState(true);
  const { csrfToken, walletAddress } = useSelector((state) => state.auth);
  const { planType, planExpiry, nextPlan, paymentPlan } = useSelector(
    (state) => state.user,
  );

  /**
   * get token address from Api
   */
  useEffect(() => {
    (async () => {
      const response = await httpRequest(
        API_URL.TOKEN_ADDRESSES,
        HTTP_METHOD.GET,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );
      if (response.code === HTTP_STATUS_CODE.OK) {
        // console.log(" token address: ", response?.data);
        const tomiIndex = response?.data.findIndex(
          (item) => item.name === "tomi",
        );
        const usdtIndex = response?.data.findIndex(
          (item) => item.name === "usdt",
        );
        setTomiTokenAddress(
          response?.data[tomiIndex].address ?? env.tokenContractAddress,
        );
        setUsdtTokenAddress(
          response?.data[usdtIndex].address ?? env.tokenContractAddressUsdt,
        );
      }
    })();
  }, []);

  /**
   * Check Plan's expiration
   */
  useEffect(() => {
    const checkPlanExpiry = (planExpiry) => {
      const today = new Date();
      const expiryDate = new Date(planExpiry);

      if (expiryDate > today) {
        return false;
      } else {
        return true;
      }
    };
    if (planExpiry) {
      const exp = checkPlanExpiry(planExpiry);
      setPlanExpired(exp);
    }
  }, [planExpiry]);

  /**
   * Fetching user's balance
   */
  const fetchBalance = async (contractAddress) => {
    try {
      console.log("contractAddress : ", contractAddress);

      const erc20 = new ERC20Contract();
      await erc20.init(walletProvider, contractAddress);
      const balance = await erc20.getBalance(walletAddress);
      const decimals = await erc20.getDecimals(walletAddress);
      console.log("decimals : ", decimals);
      console.log("balance : ", balance);
      setDecimals(Number(decimals));
      if (balance > 0) {
        setUserBalance(Number(balance) / 10 ** (Number(decimals) ?? 18));
      }
      return true;
    } catch (error) {
      console.error("Error fetching balance:", error);
      return false;
    }
  };

  useEffect(() => {
    if (planType) {
      const index = plans.findIndex((e) => e.name === planType);
      setActiveIndex(index);
    }
  }, [plans, planType]);

  /**
   * fetch plans and market cap value
   */
  useEffect(() => {
    try {
      if (walletProvider) {
        dispatch(fetchPlans({ csrfToken, handleLogOut }));
        (async () => {
          const response = await httpRequest(
            `${API_URL.COIN_MARKET_CAP}`,
            HTTP_METHOD.GET,
            null,
            null,
            null,
            handleLogOut,
          );

          if (response.code === HTTP_STATUS_CODE.OK) {
            setCapValueUsdt(Number(response.data?.USDT ?? 0));
            setCapValueTomi(Number(response.data?.TOMI ?? 0));
          }
        })();
      }
    } catch (error) {
      console.log("Error while fetching cap api : ", error);
    }
  }, [walletProvider]);

  /**
   * call fetch balance function when tomi token address and usdt token address is available
   */
  useEffect(() => {
    if (walletProvider && tomiTokenAddress && usdtTokenAddress) {
      (async () => {
        await fetchBalance(
          tomiTokenSelected ? tomiTokenAddress : usdtTokenAddress,
        );
      })();
    }
  }, [walletProvider, tomiTokenSelected, tomiTokenAddress, usdtTokenAddress]);

  /**
   *
   * @param {*} e
   * Payment process in tomi and usdt
   */
  const handleSubmit = async (e) => {
    if (e?.holdings > 0 || e.name === "free") {
      if (userBalance >= e?.holdings) {
        setLoading(e?.name);
        const response = await httpRequest(
          API_URL.TRIAL_PLAN,
          HTTP_METHOD.POST,
          null,
          {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
          null,
          handleLogOut,
        );
        if (response.code === HTTP_STATUS_CODE.OK) {
          navigate(Path.DASHBOARD);
          dispatch(setPlanType(e?.name));
        } else {
          toast.error("Something went wrong!");
        }
      } else {
        toast.error(
          `A minimum of ${e?.holdings ?? 1000} Tomi tokens is required to access the free plan`,
        );
      }
      setLoading("");
    } else {
      const price = tomiTokenSelected ? e?.price : e?.price_usdt;
      const { tokenVal, tokenWithDecimals } = fetchTokenPrice(price);

      console.log("tokenVal :: ", tokenVal);
      console.log("userBalance ::: ", userBalance);
      console.log("tokenWithDecimals : ", tokenWithDecimals);

      const tokenAddress = tomiTokenSelected
        ? tomiTokenAddress
        : usdtTokenAddress;

      try {
        if (userBalance >= tokenVal) {
          setLoading(e?.name);

          const erc20 = new ERC20Contract();
          await erc20.init(walletProvider, tokenAddress);

          const allowanceVal = await erc20.getAllowance(walletAddress);
          console.log("Already have allowance " + allowanceVal);

          let allowanceHash = "";
          if (Number(allowanceVal) / 10 ** Number(decimals) < tokenVal) {
            const response = await erc20.approve(
              env.subscriptionContractAddress,
              tokenVal,
            );
            console.log("Taking allowance : ", allowanceVal);

            allowanceHash = response?.hash;
          } else {
            allowanceHash = "hash";
          }

          if (allowanceHash) {
            setTimeout(() => {
              (async () => {
                try {
                  setLoading(e?.name);
                  const subscription =
                    await SubscriptionContract.init(walletProvider);

                  const response = await httpRequest(
                    API_URL.PAID_PLAN,
                    HTTP_METHOD.POST,
                    {
                      price: tokenVal,
                      plan_name: e?.name,
                      price_usd: price,
                      token_address: tokenAddress,
                    },
                    {
                      "Content-Type": "application/json",
                      "X-CSRF-Token": csrfToken,
                    },
                    null,
                    handleLogOut,
                  );

                  console.log("response : ", response);

                  console.log("amount passing to subscibe : ", tokenVal);

                  const result = await subscription.subscribePlan(
                    e?.plan_key,
                    tokenAddress,
                    tokenWithDecimals,
                  );

                  console.log("result transaction hash: ", result.hash);

                  if (result?.hash) {
                    navigate(Path.DASHBOARD);
                    dispatch(setNextPlan(e?.name));
                    dispatch(setPlanId(response?.data?.ID));
                    dispatch(setPaymentProcessModal(true));
                  } else {
                    dispatch(setNextPlan(null));
                    dispatch(setPlanId(null));
                    dispatch(setPaymentProcessModal(false));
                  }
                  setLoading("");
                } catch (error) {
                  console.log("Error while subscribing : ", error);

                  setLoading("");
                  dispatch(setNextPlan(null));
                  dispatch(setPlanId(null));
                  dispatch(setPaymentProcessModal(false));

                  if (error instanceof Error) {
                    const err = JSON.parse(JSON.stringify(error));

                    console.log("err : ", err);
                    if (err?.code === "ACTION_REJECTED") {
                      toast.error(
                        "Oops! It looks like you declined the request from your wallet",
                      );
                      return;
                    }

                    toast.error(
                      err?.reason ??
                        err?.error?.message ??
                        "Something went wrong",
                    );

                    return;
                  }

                  toast.error(error?.message ?? "Something went wrong");
                }
              })();
            }, 2000);
          } else {
            toast.error("Approval request does not succeed!");
            setLoading("");
          }
        } else {
          toast.error(
            `A minimum of ${Math.round(tokenVal * 100) / 100} Tomi tokens is required to access the ${e?.name} plan`,
          );
        }
      } catch (error) {
        console.log("error while upgrading plan to pro : ", error);
        if (error?.code === "ACTION_REJECTED") {
          toast.error(
            "Oops! It looks like you declined the request from your wallet",
          );
        }
        setLoading("");
      }
    }
  };

  const generateFeatures = (plan) => {
    return [
      `${formatStorageSize(plan?.storage)} Storage included`,
      `${formatStorageSize(plan?.meta_data?.bandwidth)} Bandwidth`,
      `${plan?.meta_data?.workspace} ${plan?.meta_data?.workspace == 1 ? "Workspace Member" : "Workspace Members"}`,
      `${plan?.meta_data?.gateway}  ${plan?.meta_data?.gateway === 1 ? "Gateway" : "Gateways"}`,
      `${formatNumber(plan?.meta_data?.requests)}  requests${perMonth}`,
      `Maximum number of files pinned can be ${formatNumber(plan?.meta_data?.pinned)}`,
      `${plan?.meta_data?.html_file === true ? "HTML Upload Supported" : "No HTML Upload Supported"}`,
      `${plan?.meta_data?.html_file === true ? "API key Access" : "No API key Access"}`,
    ];
  };

  const handleToggle = (checked) => {
    setTomiTokenSelected(checked);
  };

  const fetchTokenPrice = (priceUsd) => {
    try {
      if (tomiTokenSelected) {
        const amount = Number(priceUsd) / Number(capValueTomi) ?? 0;
        const tokenVal = parseFloat(amount.toFixed(Number(decimals)));

        const tokenWithDecimals = ethers.parseUnits(
          tokenVal.toString(),
          Number(decimals),
        );

        return {
          tokenVal,
          tokenWithDecimals: tokenWithDecimals,
        };
      } else {
        const amount = Number(priceUsd) / Number(capValueUsdt) ?? 0;
        const tokenVal = parseFloat(amount.toFixed(Number(decimals)));

        const tokenWithDecimals = ethers.parseUnits(
          tokenVal.toString(),
          Number(decimals),
        );

        return {
          tokenVal,
          tokenWithDecimals: tokenWithDecimals,
        };
      }
    } catch (error) {
      return null;
    }
  };

  const buttonDisable = (plan, index) => {
    if (nextPlan) {
      return true;
    } else if (isLoading) {
      return true;
    } else if (planExpired && index === activeIndex) {
      return false;
    } else if (index <= activeIndex) {
      return true;
    }
  };

  const buttonText = (plan, index) => {
    if (isLoading === plan?.name) {
      return <Spin indicator={<LoadingOutlined spin />} />;
    } else if (paymentPlan === plan?.name) {
      return "Processing...";
    } else if (planExpired && index === activeIndex) {
      return "Renew Plan";
    } else if (index === activeIndex) {
      return "Activated";
    } else if (plan?.name === "free") {
      return `Start ${capitalizeFirstLetter(plan?.name)} Plan`;
    } else {
      return `Start ${capitalizeFirstLetter(plan?.name)} Plan`;
    }
  };

  return (
    <div className="accountType">
      <h2>Choose Your Plan </h2>
      <div className="accountType_switch">
        <div
          className={`accountType_switch_head ${!tomiTokenSelected && "pink"}`}
        >
          <div>
            <img src={faitIcon} alt="" />
            <h5>Pay with USDT</h5>
          </div>
        </div>
        <Switch defaultChecked onChange={handleToggle} />
        <div
          className={`accountType_switch_head ${tomiTokenSelected && "pink"}`}
        >
          <div>
            <img src={TomiToken} alt="" />
            <h5>Pay with TOMI token</h5>
          </div>
        </div>
      </div>
      <div className="accountType_cards">
        {plans &&
          plans?.map((plan, index) => (
            <AccountCard
              data={plan}
              key={index}
              title={plan?.name}
              price={`$${tomiTokenSelected ? (plan?.price ?? 0) : (plan.price_usdt ?? 0)}`}
              duration="mo"
              valueIconSrc={TomiToken}
              description={plan?.description || ""}
              features={generateFeatures(plan)}
              tomiImg={tomiTokenSelected}
              buttonText={buttonText(plan, index)}
              onClick={handleSubmit}
              value={roundToTwoDecimalPlaces(
                fetchTokenPrice(
                  tomiTokenSelected
                    ? (plan?.price ?? 0)
                    : (plan.price_usdt ?? 0),
                )?.tokenVal ?? 0,
              )}
              icon={
                plan?.name == "pro" ? (
                  <ProAccountIcon />
                ) : plan?.name === "scale" ? (
                  <ScaleIcon />
                ) : null
              }
              btnDisabled={buttonDisable(plan, index)}
              // btnActive={
              //   nextPlan
              //     ? false
              //     : planExpired && index === activeIndex
              //       ? true
              //       : index > activeIndex
              // }
              className={`dashboard_account_card  ${index > activeIndex && "activePlan"}`}
              loading={loading}
            />
          ))}
      </div>
    </div>
  );
}

export default AccountTypeDashboard;
