import React from "react";
import Cookies from "js-cookie";
import { isAddress } from "ethers";
import toast from "react-hot-toast";
import confetti from "canvas-confetti";
import { HTTP_METHOD, OPERATIONS } from "../constants";
import httpRequest from "./httpsRequest";
import moment from "moment/moment";
import "../Pages/FileManagement/FileManagement.scss";
import { env } from "../constants/env";
import fileIcon from "../Assets/images/fileIcon.svg";
import videoIcon from "../Assets/images/videoIcon.svg";
import audioIcon from "../Assets/images/audioIcon.svg";
import Box from "../Assets/images/box.png";
import folderImg from "../Assets/images/folder.png";
import {
  faTrash,
  faPenToSquare,
  faCircleInfo,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DownloadFile,
  FolderIcon,
  FolderICon,
  ShareIcon,
  ThreeDotHorizontal,
} from "../Assets/Assets";
import { Col, Dropdown, Empty, Skeleton, Table, Tooltip } from "antd";
import PropTypes from "prop-types";
import NoDataPic from "../Assets/images/notfound.svg";
// import Shildicon from "../Assets/images/shildicon.png";
import Shildicongrid from "../Assets/images/Shildicongrid.png";
import { validateMail } from "./validationUtils";
import GridWrapper from "../Pages/FileManagement/gridWrapper";
import NoData from "../Common/noData/NoData";

export const getImage = (format, ipfsUrl, mediaId, type) => {
  let img;
  img = fileIcon;
  if (!format) {
    if (type === "folder") {
      img = folderImg;
    } else {
      img = Box;
    }
  } else if (format?.includes("image")) {
    if (format === "image/svg+xml") {
      img = fileIcon;
    } else {
      img = `${env.ipfsUrl}/${ipfsUrl}/${mediaId}`;
    }
  } else if (format?.includes("video")) {
    img = videoIcon;
  } else if (format?.includes("audio")) {
    img = audioIcon;
  }
  return img;
};

export const formatNumber = (number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return number.toString();
};

export const convertDate = (isoString) => {
  const date = new Date(isoString);
  const options = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = date
    .toLocaleString("en-US", options)
    .replace(",", " | ");
  return formattedDate;
};

export const extractFileName = (filePath) => {
  if (!filePath) return "-";
  const file = filePath.includes("/") ? filePath.split("/").pop() : filePath;
  return file.charAt(0).toUpperCase() + file.slice(1);
};

export const formatStorageSize = (bytes) => {
  if (bytes === undefined || bytes === null || isNaN(bytes))
    return "Unknown size";
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

let genericData;
const dataType = ["Bytes", "KB", "MB", "GB", "TB"];
export const bytesToGenericUnit = (data, index = 0) => {
  if (data == 0) return { data, type: dataType[index] };

  genericData = data / 1024;
  if (genericData > 1) {
    index++;
    return bytesToGenericUnit(genericData, index);
  } else {
    const maxData = genericData * 1024;
    return { maxData, type: dataType[index] };
  }
};

export const YAxisMaxValue = (value) => {
  const valueStr = value.toString();
  const length = valueStr.length;
  if (length == 0) return 0;
  const firstDigit = parseInt(valueStr[0]) + 1;
  const ceilingValue = firstDigit * Math.pow(10, length - 1);
  return ceilingValue;
};

export const dateToWeek = (dateString) => {
  let dateObject = new Date(dateString);
  return dateObject.toLocaleString("en-us", { weekday: "long" });
};

export const displayDates = (dateString, period) => {
  return period == "month"
    ? moment(dateString).format("DD MMM")
    : moment(dateString).format("LT");
};

export const convertBytesToSpecific = (bytes, type) => {
  if (bytes == 0) return 0;

  switch (type) {
    case "Bytes": {
      return bytes;
    }
    case "KB": {
      return (bytes / 1024).toFixed(2);
    }
    case "MB": {
      return (bytes / (1024 * 1024)).toFixed(2);
    }
    case "GB": {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2);
    }
  }
};

export function capitalizeFirstLetter(str) {
  if (!str) return str; // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatString(str = "", startLen = 7, endLen = 7) {
  if (!str) return str; // Handle empty strings
  return `${str.substring(0, startLen)}...${str.substring(str?.length - endLen, str?.length)}`;
}

export function formatName(str = "") {
  if (!str) return str;
  return str?.length > 12
    ? `${str.substring(0, 4)}...${str.substring(str?.length - 4, str?.length)}`
    : str;
}

function getFileType(mimeType) {
  const parts = mimeType.split("/");
  return parts.length > 1 ? parts[1] : null; // Return the file type or null if not found
}

export const convertDateFormat = (dateString) => {
  if (!dateString) return "";
  const cleanedString = dateString.replace("|", "").trim();
  const [datePart, timePart] = cleanedString.split(" at ");
  const date = new Date(`${datePart} ${timePart}`);

  if (isNaN(date.getTime())) {
    console.error("Invalid date format");
    return null; // Return null for invalid dates
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:00`;
};

export const formatIsoDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    console.error("Invalid date format");
    return "";
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[date.getMonth()];
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  return `${month} ${day} | ${year} at ${formattedHours}:${minutes} ${ampm}`;
};

const storageUnits = {
  GB: 1e9,
  MB: 1e6,
  KB: 1e3,
  B: 1,
};

export const convertToBytes = (storage) => {
  const [value, unit] = storage.split(" ");
  const numericValue = parseFloat(value);

  return numericValue * (storageUnits[unit] || 0);
};

export function roundToTwoDecimalPlaces(value) {
  return Math.round(value * 100) / 100;
}

export const downloadMedia = async (url, format, handleLogOut = null, name) => {
  try {
    const response = await httpRequest(
      url,
      HTTP_METHOD.GET,
      null,
      null,
      "arraybuffer",
      handleLogOut,
    );

    toast.loading("Downloading");

    if (!response.error) {
      const imageBlob = new Blob([response], { type: format });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(imageBlob);
      let fileName = `downloaded-${+new Date()}.${getFileType(format)}`;
      link.download = name ? name : fileName;
      link.click();
      URL.revokeObjectURL(link.href);
      toast.dismiss();
      toast.success("Downloaded");
    } else {
      toast.dismiss();
      toast.error("Downloading failed");
      console.error("Error fetching image:", response.error);
    }
  } catch (error) {
    toast.error("Resource does not exist");
    console.error("Error downloading the media:", error);
  }
};

export const viewMedia = async (url, format, handleLogOut = null) => {
  try {
    const response = await httpRequest(
      url,
      HTTP_METHOD.GET,
      null,
      null,
      "arraybuffer",
      handleLogOut,
    );
    if (!response.error) {
      const mediaBlob = new Blob([response], { type: format });
      const mediaURL = URL.createObjectURL(mediaBlob);

      return mediaURL;
    } else {
      toast.error("Downloading failed");
      console.error("Error fetching media:", response.error);
    }
  } catch (error) {
    toast.error("Resource does not exist");
    console.error("Error downloading the media:", error);
  }
};

export const copy = (string) => {
  if (string) {
    window.navigator.clipboard.writeText(string);
    toast.success("Copied!");
  }
  return;
};

export const removeCookie = (name, path) => {
  Cookies.remove(name, { path: path });
};

export const getCookie = (name) => {
  const cookie = Cookies.get(name);
  return cookie;
};

export const launchConfetti = () => {
  confetti({
    particleCount: 150,
    startVelocity: 80,
    spread: 1000,
    zIndex: 99999999999,
    origin: { x: 0.5, y: 0.8 },
    colors: ["#ff0000", "#00ff00", "#0000ff"], // Red, Green, Blue
  });
};

export const noExponents = function (strData) {
  try {
    let data = String(strData).split(/[eE]/);
    if (data.length === 1) return data[0];
    let z = "",
      sign = strData < 0 ? "-" : "",
      str = data[0].replace(".", ""),
      mag = Number(data[1]) + 1;
    if (mag < 0) {
      z = sign + "0.";
      while (mag++) z += "0";
      // eslint-disable-next-line no-useless-escape
      return z + str.replace(/^\-/, "");
    }
    mag -= str.length;
    while (mag--) z += "0";
    return str + z;
  } catch (error) {
    console.log("Error in no exponent", error);
  }
};

export const validateAddress = (value = "", selfAddress) => {
  if (!value) return "";
  if (!value.startsWith("0x")) {
    return "Invalid wallet address. It must start with '0x'.";
  } else if (value.length !== 42) {
    return "Invalid wallet address length. It must be 42 characters long.";
  } else if (value === selfAddress) {
    return "Owner can't share data themselves";
  } else if (!isAddress(value)) {
    return "Invalid wallet address.";
  } else {
    return "";
  }
};

export const validateEmailOrAddress = (value, selfAddress, selfMail) => {
  try {
    if (!value) return "";
    if (value === selfAddress || value === selfMail) {
      return {
        valid: false,
        error: "Owner can't share data themselves",
      };
    }
    const addressErr = validateAddress(value, selfAddress);
    let { isValidMail } = validateMail(value);

    if (isValidMail) {
      return {
        valid: true,
        error: "",
        type: "email",
      };
    } else if (!addressErr) {
      return {
        valid: true,
        error: "",
        type: "wallet_address",
      };
    } else {
      return {
        valid: false,
        error: "Please enter a valid email address Or wallet address",
      };
    }
  } catch (error) {
    console.log("Error while validating mail or address : ", error);
  }
};

export const createColumns = (handleFolderClick) => [
  {
    title: ["Name"],
    dataIndex: "container",
    key: "container",
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => {
      const nameA = a.name || "";
      const nameB = b.name || "";
      return nameA.localeCompare(nameB);
    },
    width: "20%",
  },
  {
    title: ["Files"],
    dataIndex: "files",
    key: "files",
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => a.files - b.files,
    width: "20%",
  },
  {
    title: ["Storage"],
    dataIndex: "storage",
    key: "storage",
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => {
      return convertToBytes(a.storage) - convertToBytes(b.storage);
    },
    width: "20%",
  },
  {
    title: ["Shared Count"],
    dataIndex: "shared_members",
    key: "shared_members",
    render: (e) => e ?? 0,
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => {
      return Number(a.shared_members ?? 0) - Number(b.shared_members ?? 0);
    },
    width: "20%",
  },
  {
    title: ["Created At"],
    dataIndex: "dateUpdated",
    key: "dateUpdated",
    onCell: (record) => ({
      onClick: () => handleFolderClick(record),
    }),
    sorter: (a, b) => {
      return (
        new Date(convertDateFormat(a.dateUpdated)) -
        new Date(convertDateFormat(b.dateUpdated))
      );
    },
    width: "20%",
  },
  {
    title: <div className="">Action</div>,
    dataIndex: "blankIcon",
    key: "blankIcon",
    width: "20%",
  },
];

export const getItems = (data, handleOperations) => [
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.open)}
      >
        <FolderICon /> Open Container
      </span>
    ),
    key: "0",
  },
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.edit)}
      >
        <FontAwesomeIcon icon={faPenToSquare} /> Rename Container
      </span>
    ),
    key: "1",
  },
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.share)}
      >
        <ShareIcon /> Share Container
      </span>
    ),
    key: "2",
  },
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.details)}
      >
        <FontAwesomeIcon icon={faCircleInfo} className="item_fa" />
        Container Details
      </span>
    ),
    key: "3",
  },
  {
    label: (
      <span
        className="folderMore"
        onClick={() => handleOperations(data, OPERATIONS.delete)}
      >
        <FontAwesomeIcon icon={faTrash} />
        Delete Container
      </span>
    ),
    key: "4",
  },
];

export const Foldercolumns = (handleFolderNavigation) => [
  {
    title: ["Name"],
    dataIndex: "folderName",
    key: "folderName",
    onCell: (record) => ({
      onClick: () => handleFolderNavigation(record),
    }),
    sorter: (a, b) => {
      const nameA = a.name || "";
      const nameB = b.name || "";
      return nameA.localeCompare(nameB);
    },
    width: "20%",
  },
  {
    title: <span>Type</span>,
    dataIndex: "format",
    key: "format",
    render: (data) => (
      <p className="dFlex">
        <span className="ellipsis">
          {data ? (
            extractFileName(data).length > 10 ? (
              <Tooltip title={extractFileName(data)} placement="topLeft">
                <span>{formatString(extractFileName(data), 6, 6)}</span>
              </Tooltip>
            ) : (
              extractFileName(data)
            )
          ) : (
            "Folder"
          )}
        </span>
      </p>
    ),
    onCell: (record) => ({
      onClick: () => handleFolderNavigation(record),
    }),
    sorter: (a, b) => {
      const typeA = a.format ? extractFileName(a.format) : "Folder";
      const typeB = b.format ? extractFileName(b.format) : "Folder";
      return typeA.localeCompare(typeB);
    },
    width: "20%",
  },
  {
    title: <span>Size</span>,
    dataIndex: "storage",
    key: "storage",
    onCell: (record) => ({
      onClick: () => handleFolderNavigation(record),
    }),
    sorter: (a, b) => {
      return convertToBytes(a.storage) - convertToBytes(b.storage);
    },
    width: "20%",
  },
  {
    title: <span>Last Updated</span>,
    dataIndex: "dateUpdated",
    key: "dateUpdated",
    sorter: (a, b) => {
      return (
        new Date(convertDateFormat(a.dateUpdated)) -
        new Date(convertDateFormat(b.dateUpdated))
      );
    },
    width: "20%",
  },
  {
    title: <div className="">Action</div>,
    dataIndex: "blankIcon",
    key: "blankIcon",
    width: "20%",
  },
];

export const getItemsfolder = (
  data,
  handleOperations,
  firstFolderChain,
  OPERATIONS,
) => {
  const itemType = !data.format ? "Folder" : "File";

  if (itemType === "Folder") {
    return [
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, OPERATIONS.open)}
          >
            <FolderIcon /> Open {itemType}
          </span>
        ),
        key: "0",
      },
      {
        label:
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, "share", "folder")}
            >
              <ShareIcon /> Share {itemType}
            </span>
          ) : null,
        key: "1",
      },
      {
        label:
          firstFolderChain?.container_access_level === "manage" ||
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, OPERATIONS.edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} className="item_fa" />{" "}
              Rename {itemType}
            </span>
          ) : (
            <></>
          ),
        key: "2",
      },
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, OPERATIONS.details, itemType)}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="item_fa" />
            &nbsp; {itemType} Details
          </span>
        ),
        key: "3",
      },
      {
        label:
          firstFolderChain?.container_access_level === "manage" ||
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() =>
                handleOperations(data, OPERATIONS.delete, "folder")
              }
            >
              <FontAwesomeIcon icon={faTrash} className="item_fa" />
              &nbsp; Delete {itemType}
            </span>
          ) : (
            <></>
          ),
        key: "4",
      },
    ];
  } else {
    return [
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, "open")}
          >
            <FolderIcon /> Open
          </span>
        ),
        key: "0",
      },
      {
        label:
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, "share", "file")}
            >
              <ShareIcon /> Share {itemType}
            </span>
          ) : null,
        key: "1",
      },
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, "details", itemType)}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="item_fa" />
            {itemType} Details
          </span>
        ),
        key: "2",
      },
      {
        label:
          firstFolderChain?.container_access_level === "manage" ||
          firstFolderChain?.container_access_level === "all" ? (
            <span
              className="folderMore"
              onClick={() => handleOperations(data, "delete", "file")}
            >
              <FontAwesomeIcon icon={faTrash} className="item_fa" />
              Delete {itemType}
            </span>
          ) : (
            <></>
          ),
        key: "3",
      },
      {
        label: (
          <span
            className="folderMore"
            onClick={() => handleOperations(data, OPERATIONS.download)}
          >
            <FontAwesomeIcon icon={faDownload} className="item_fa" />
            Download {itemType}
          </span>
        ),

        key: "4",
      },
    ];
  }
};

export const ListViewContent = ({ loading, dataLoading, data, columns }) => {
  return (
    <>
      {loading || dataLoading ? (
        <div className="tableSkeleton" data-testid="skeleton-container">
          <Skeleton active paragraph={{ rows: 5 }} />
        </div>
      ) : (
        <Table
          id="insideContainer"
          locale={{
            emptyText: (
              <Empty
                image={<img src={NoDataPic} alt="empty" />}
                description="No Data Found"
              />
            ),
          }}
          // scroll={{ y: 525, x: "auto" }}
          showSorterTooltip={{ target: "sorter-icon" }}
          columns={columns}
          dataSource={data}
          pagination={false}
          className="commontable"
        />
      )}
    </>
  );
};

export const GridViewContent = ({
  loading,
  dataLoading,
  folders,
  data,
  fetchMoreData,
  isMoreData,
  notFound,
  handleFolderNavigation,
  downloadingId,
  handleDownload,
  getItemsfolder,
  handleOperations,
  firstFolderChain,
  OPERATIONS,
  getImage,
}) => {
  return (
    <>
      {loading || dataLoading ? (
        <div className="gridFolder">
          {[1, 2, 3, 4].map((_, index) => (
            <Col
              key={index + "skele"}
              lg={6}
              className="setting_card commonCardBg"
            >
              <Skeleton active paragraph={{ rows: 2 }} />
              <Skeleton.Button active />
            </Col>
          ))}
        </div>
      ) : (
        <GridWrapper
          lengthData={data.length}
          functionNext={fetchMoreData}
          hasMore={isMoreData}
        >
          {folders && !folders.length ? (
            <NoData
              icon
              NotFound={notFound}
              customStyle={{ color: "white", marginRight: "2.5%" }}
              textColor="white"
            />
          ) : (
            <div className="fileFolder_cards">
              {folders.map((media, i) => (
                <div
                  className="fileFolder_cards_inner commonCardBg"
                  key={i + "fileFolder_cards"}
                >
                  <img
                    onClick={() => handleFolderNavigation(media)}
                    src={
                      media.is_encrypted
                        ? Shildicongrid
                        : getImage(
                            media?.format,
                            media?.ipfs_url,
                            true,
                            media?.id,
                            media.is_encrypted,
                          )
                    }
                    alt={
                      media?.is_encrypted
                        ? "Encrypted Media"
                        : "Media Thumbnail"
                    }
                  />
                  <div className="fileFolderdata">
                    <Tooltip
                      key={media.name}
                      placement="bottomRight"
                      title={media?.name}
                    >
                      <h3 className="ellipsis">{media?.name}</h3>
                    </Tooltip>
                    <span>{convertDate(media?.updated_at) ?? ""}</span>
                    <div className="fileFolderdata_icons">
                      <span
                        disabled={downloadingId === media?.id}
                        className={`${
                          downloadingId === media?.id
                            ? "cursor-notAllowed"
                            : "cursor-pointer"
                        }`}
                        onClick={() => {
                          if (downloadingId !== media?.id) {
                            handleDownload(media?.id, media?.format);
                          }
                        }}
                      >
                        {media?.format && <DownloadFile />}
                      </span>
                      <Dropdown
                        menu={{
                          items: getItemsfolder(
                            media,
                            handleOperations,
                            firstFolderChain,
                            OPERATIONS,
                          ),
                        }}
                        trigger={["click"]}
                      >
                        <a
                          className="threeDot"
                          onClick={(e) => e.preventDefault()}
                        >
                          <ThreeDotHorizontal />
                        </a>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </GridWrapper>
      )}
    </>
  );
};

ListViewContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};
GridViewContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  folders: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  fetchMoreData: PropTypes.func.isRequired,
  isMoreData: PropTypes.bool.isRequired,
  notFound: PropTypes.string,
  handleFolderNavigation: PropTypes.func.isRequired,
  downloadingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleDownload: PropTypes.func.isRequired,
  getItemsfolder: PropTypes.func.isRequired,
  handleOperations: PropTypes.func.isRequired,
  firstFolderChain: PropTypes.array,
  OPERATIONS: PropTypes.object.isRequired,
  getImage: PropTypes.func.isRequired,
};
