import {
  convertDate,
  copy,
  createColumns,
  formatStorageSize,
  formatString,
  getItems,
  validateAddress,
} from "../../Utils/helpers";
import {
  fetchContainers,
  updateContainer,
  isNewCreated,
  setIsNewCreated,
} from "../../store/slices/containerSlice";
import {
  addToBreadcrumb,
  resetBreadcrumb,
} from "../../store/slices/breadcrumbSlice";

import "./FileManagement.scss";
import PropTypes from "prop-types";
import Box from "../../Assets/images/box.png";
import { Empty, Table, Dropdown, Tooltip, Skeleton, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import useLogout from "../../CustomHooks/useLogout";
import NoData from "../../Assets/images/notfound.svg";
import { useDispatch, useSelector } from "react-redux";
import { React, useEffect, useRef, useState } from "react";
import {
  API_URL,
  HTTP_METHOD,
  HTTP_STATUS_CODE,
  INFINITE_SCROLLBAR,
  OPERATIONS,
} from "../../constants";
import CommonModal from "../../Common/CommonModal/CommonModal";
import {
  ThreeDots,
  ShareIcon,
  CopyIcon,
  ContainerImage,
  DeleteIcon,
} from "../../Assets/Assets";
import InputCustom from "../../Common/Components/InputCustom/InputCustom";
import httpRequest from "../../Utils/httpsRequest";
import toast from "react-hot-toast";
import useKeyPress, { validateMail } from "../../Utils/validationUtils";
import { sendInvitation, shareItem } from "../../store/slices/shareSlice";
import CustomSelect from "../../Common/Components/CustomSelect/CustomSelect";
import ButtonCustom from "../../Common/Components/ButtonCustom/ButtonCustom";
import "../../Common/Components/ShareContainer/ShareContainer.scss";
import { env } from "../../constants/env";
import { LoadingOutlined } from "@ant-design/icons";
import { setContainerSharedTabVisible } from "../../store/slices/userSlice";
import { setIsNew } from "../../store/slices/authSlice.js";
import {
  fetchUserGuide,
  setIsMemberUserGuide,
} from "../../store/slices/userGuideSlice";

function FileMainTable({ searchTerm, isFileGuide, isFileGuideStore }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogOut = useLogout();
  const isLoading = useRef(false);

  let page = useRef(INFINITE_SCROLLBAR.DEFAULT_PAGE);
  let limit = useRef(INFINITE_SCROLLBAR.DEFAULT_LIMIT);

  const [loading, setLoading] = useState(false);
  const [isBtnDisable, setDisable] = useState(true);
  const [isDeleteBtnDisable, setDeleteBtnDisable] = useState(false);
  const [isShareBtnDisable, setSharebtnDisable] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [changeInContainer, setChangeInContainer] = useState(false);
  const [editContainerError, setEditContainerError] = useState("");
  const [containerCurrentName, setContainerCurrentName] = useState(null);
  const [containerDetailView, setContainerDetailView] = useState(null);
  const [containerDetailData, setContainerDetailData] = useState(null);
  const [editContainer, setEditContainer] = useState({ name: "", id: "" });
  const [shareableId, setShareableId] = useState(null);
  const [mediaToShare, setMediaToShare] = useState(null);
  const [accessLevel, setAccessLevel] = useState("read");
  const [accessType, setAccessType] = useState("private");
  const [walletAddress, setWalletAddress] = useState("");
  const [invetationEmail, setinvationEmail] = useState("");
  const [isRemoveVisible, setisRemoveVisible] = useState(false);
  const [selectedContainerId, setSelectedContainerId] = useState("");
  const [wltErr, setWltErr] = useState("");
  const { csrfToken, isMemberGuide } = useSelector((state) => state.auth);
  const auth = useSelector((state) => state.auth);
  const { containers, isMoreData, status, error } = useSelector(
    (state) => state.containers,
  );
  const { isMemberGuideStore } = useSelector((state) => state.userGuide);
  const [invitationModalVisible, setInvitationModalVisible] = useState(false);
  useEffect(() => {
    if (status === "loading") {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if ((!walletAddress && accessType === "private") || wltErr) {
      setSharebtnDisable(true);
    } else {
      setSharebtnDisable(false);
    }
  }, [walletAddress, accessType, wltErr, status, error, dispatch, searchTerm]);

  useEffect(() => {
    if (containers?.length > 0) {
      dispatch(setContainerSharedTabVisible(false));
      dispatch(setIsNew(false));
    } else {
      dispatch(setIsNew(true));
    }
  }, [containers.length]);

  useEffect(() => {
    if (
      !editContainer.name ||
      editContainerError ||
      !changeInContainer ||
      editContainer?.name?.trim() === containerCurrentName ||
      editContainer.name.length < 3
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    editContainer.name,
    editContainerError,
    changeInContainer,
    containerCurrentName,
  ]);

  useEffect(() => {
    if (isNewCreated) {
      page.current = INFINITE_SCROLLBAR.DEFAULT_PAGE;
      resetScrollBar();
      setIsNewCreated(false);
    }
  }, [isNewCreated, setIsNewCreated]);

  useEffect(() => {
    async function onScroll() {
      try {
        if (
          tableBody.scrollHeight -
            (tableBody.scrollTop + tableBody.clientHeight) <=
            2 &&
          isMoreData &&
          !isLoading.current
        ) {
          isLoading.current = true;
          const loader = document.createElement("div");
          loader.setAttribute("class", "loader");
          tableBody.appendChild(loader);
          page.current += 1;

          const resultAction = await dispatch(
            fetchContainers({
              handleLogOut,
              searchTerm,
              page: page.current,
              limit: limit.current,
              prevData: containers,
              csrfToken,
            }),
          ).unwrap();
          if (resultAction) {
            isLoading.current = false;
            tableBody.removeChild(loader);
          }
        }
      } catch (error) {
        console.log("Error  while applying on wheel event: ", error);
      }
    }
    const myContainer = document.querySelector("#myContainer");
    const elem = myContainer?.firstChild;
    const tableBody = elem?.lastChild;

    if (tableBody) {
      tableBody.addEventListener("scroll", onScroll, { passive: true });
      return () => {
        tableBody.removeEventListener("scroll", onScroll);
      };
    }
  }, [isMoreData, containers, isLoading.current, loading]);

  useEffect(() => {
    page.current = INFINITE_SCROLLBAR.DEFAULT_PAGE;
    resetScrollBar();
    dispatch(
      fetchContainers({
        handleLogOut,
        searchTerm,
        page: INFINITE_SCROLLBAR.DEFAULT_PAGE,
        limit: INFINITE_SCROLLBAR.DEFAULT_LIMIT,
        prevData: null,
        csrfToken,
      }),
    );
  }, [searchTerm]);

  useEffect(() => {
    dispatch(resetBreadcrumb());
  }, []);

  function resetScrollBar() {
    const myContainer = document.querySelector("#myContainer");
    const elem = myContainer?.firstChild;
    const tableBody = elem?.lastChild;
    if (tableBody) {
      tableBody.scrollTop = 0;
    }
  }

  const handleFolderClick = (folder) => {
    dispatch(
      addToBreadcrumb({
        id: `/folder-management/container/${folder?.id}`,
        name: folder?.name,
        container_access_level: "all",
      }),
    );
    navigate(`/folder-management/container/${folder?.id}`);
  };

  const columns = createColumns(handleFolderClick);

  const handleChange = (e) => {
    setEditContainer({ ...editContainer, name: e.target.value });
    setChangeInContainer(true);
  };

  const handleChangeShare = (field, value) => {
    switch (field) {
      case "identifier": {
        setWalletAddress(value.trim());
        setinvationEmail(value.trim());
        const err = validateAddress(value, auth.walletAddress);
        const email = validateMail(value);
        if (!email.isValidMail && err !== "") {
          setWltErr("Plese Enter valid email or wallet address.");
        } else {
          setWltErr("");
        }
        break;
      }
      case "accessLevel":
        setAccessLevel(value);
        break;
      case "accessType":
        setAccessType(value);
        break;
      default:
        break;
    }
  };

  const handleClearError = () => {
    setEditContainerError("");
  };

  const deleteContainer = async () => {
    try {
      setDeleteBtnDisable(true);
      const response = await httpRequest(
        `${API_URL.DELETE_CONTAINER}${selectedContainerId}`,
        HTTP_METHOD.DELETE,
        null,
        {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        null,
        handleLogOut,
      );

      if (response?.code === HTTP_STATUS_CODE.OK) {
        dispatch(fetchContainers({ handleLogOut, csrfToken }));
        toast.success(response?.message || "Successfully  deleted container");
      } else {
        toast.error(response?.message || "Something went wrong");
      }
      setDeleteBtnDisable(false);
      setisRemoveVisible(false);
    } catch (error) {
      console.log("Error while deleting container : ", error);
    }
  };

  /** edit container
   *  handling click function
   *  @param selectedId
   * */
  const handleClick = async () => {
    if (
      isBtnDisable === false &&
      loading === false &&
      editContainerError === "" &&
      isModalVisible === true
    ) {
      try {
        setDisable(true);
        setLoading(true);
        const resultAction = await dispatch(
          updateContainer({
            selectedId: editContainer?.id,
            newName: editContainer?.name.trim(),
            handleLogOut,
            csrfToken,
          }),
        ).unwrap();

        if (resultAction?.code === HTTP_STATUS_CODE.OK) {
          toast.success(
            resultAction?.message || "Container Update successfully!",
          );
        } else {
          toast.error(resultAction?.error?.message || "Something went wrong");
        }
      } catch (error) {
        console.log("Error while editing container: ", error);
        toast.error(error || "Something went wrong");
      } finally {
        setDisable(false);
        closeModal();
      }
      page.current = INFINITE_SCROLLBAR.DEFAULT_PAGE;
      setLoading(false);
      resetScrollBar();
      setEditContainer({
        name: "",
        id: "",
      });
    }
  };

  useKeyPress("Enter", handleClick, [handleClick]);

  const handleOperations = (data, action) => {
    switch (action) {
      case OPERATIONS.open:
        handleFolderClick(data);
        break;

      case OPERATIONS.edit:
        setEditContainer({
          name: data?.name,
          id: data?.id,
        });
        setIsModalVisible(true);
        setContainerCurrentName(data?.name);
        break;
      case OPERATIONS.details:
        setContainerDetailData(data);
        setContainerDetailView(true);
        break;
      case OPERATIONS.delete:
        setisRemoveVisible(true);
        setSelectedContainerId(data?.id);
        break;
      case OPERATIONS.share:
        setMediaToShare(data);
        setShareableId(data?.id);
        setIsShareModalVisible(true);
        break;

      default:
        break;
    }
  };

  const data =
    containers &&
    containers.map((container, index) => {
      return {
        ...container,
        key: container.id,
        container: [
          <span key={`upload${index + 1}`} className="container-ellipsis">
            <span className="dFlex">
              <img src={Box} alt="" />
              <span>
                {container?.name.length < 20 ? (
                  container?.name
                ) : (
                  <Tooltip
                    key={`upload${index + 1}`}
                    placement="topLeft"
                    title={container?.name}
                  >
                    <span>{formatString(container?.name, 6, 6)}</span>
                  </Tooltip>
                )}
              </span>
            </span>
          </span>,
        ],
        files: container.media_count || "0",
        storage: formatStorageSize(container?.storage),
        dateUpdated: convertDate(container.created_at),
        blankIcon: (
          <Dropdown
            menu={{
              items: getItems(container, handleOperations),
            }}
            trigger={["click"]}
          >
            <a className="threeDot" onClick={(e) => e.preventDefault()}>
              <ThreeDots />
            </a>
          </Dropdown>
        ),
      };
    });

  const handleShare = async () => {
    try {
      setSharebtnDisable(true);
      const res = await dispatch(
        shareItem({
          identifier: walletAddress,
          shareable_id: shareableId,
          shareable_type: "container",
          access_level: accessType === "private" ? accessLevel : "read",
          access_type: accessType,
          handleLogOut,
          csrfToken,
        }),
      );

      if (res?.meta?.requestStatus === "fulfilled") {
        toast.success(res?.payload?.message || "Successfully Shared");
      }
      console.log("payload :  ", res?.payload);

      if (
        res?.meta?.requestStatus === "rejected" &&
        res?.payload ===
          "error: the email address provided for this user does not exist."
      ) {
        setInvitationModalVisible(true);
      }
      if (!isMemberGuide && !isMemberGuideStore) {
        await dispatch(
          fetchUserGuide({
            payload: "is_member_guide",
            handleLogOut,
            csrfToken,
          }),
        );
      }
      dispatch(setIsMemberUserGuide(true));
      closeModal();
    } catch (error) {
      console.error("Error while sharing:", error);
      if (
        !error.includes("your subscription has expired") ||
        !error.includes("sharing limit exceeded") ||
        !error.includes("resource is already shared publicly")
      ) {
        toast.error("Something went wrong");
      }
      closeModal();
    }
  };

  const handleSendInvitations = () => {
    dispatch(
      sendInvitation({
        email: invetationEmail,
        handleLogOut,
      }),
    )
      .unwrap()
      .then(() => {
        setInvitationModalVisible(false);
        setinvationEmail("");
      })
      .catch((error) => {
        setInvitationModalVisible(false);
        setinvationEmail("");
        console.error("Error sending invitation:", error);
      });
  };

  const closeModal = () => {
    setShareableId("");
    handleClearError();
    setContainerDetailView(false);
    setIsModalVisible(false);
    setChangeInContainer(false);
    setSharebtnDisable(false);
    setIsShareModalVisible(false);
    setMediaToShare(null);
    setAccessLevel("read");
    setAccessType("private");
    setWalletAddress("");
    setisRemoveVisible(false);
  };

  return (
    <div className="fileTableComp">
      {!loading ? (
        <Table
          id="myContainer"
          responsive={true}
          columns={columns}
          dataSource={data}
          pagination={false}
          className="commontable"
          // scroll={{ y: 525 }}
          locale={{
            emptyText: (
              <Empty
                image={<img src={NoData} alt="empty" />}
                description="No Data Found"
              />
            ),
          }}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
        />
      ) : (
        <div className="tableSkeleton" data-testid="skeleton-container">
          {(isFileGuideStore || isFileGuide) && (
            <Skeleton active paragraph={{ rows: 5 }} />
          )}
        </div>
      )}

      <CommonModal
        visible={isModalVisible}
        title="Rename Container"
        BtnText="Continue"
        paragraph="You can make the necessary changes to your container below. Once done, click 'Continue' to save."
        customBtutton
        icon={<ContainerImage />}
        onClick={() => handleClick()}
        btnDisabled={isBtnDisable}
        content={
          <InputCustom
            label
            required
            regularInput
            minLength={3}
            maxLength={50}
            placeholder="Enter Here"
            value={editContainer?.name}
            onChange={handleChange}
            error={editContainerError}
            onClearError={handleClearError}
            labletext="Name"
          />
        }
        onOk={closeModal}
        onCancel={closeModal}
      />

      <CommonModal
        className="containerModal"
        visible={containerDetailView}
        title="Container Details"
        BtnText="Continue"
        icon={<ContainerImage />}
        content={
          <div>
            <div className="containerModal_bottom">
              <div>
                <p>Name</p>{" "}
                <h4 className="ellipsis">{containerDetailData?.name}</h4>
              </div>
              <div>
                <p>Files </p> <h4>{containerDetailData?.media_count} </h4>
              </div>
              <div>
                <p>Size</p>{" "}
                <h4>
                  {formatStorageSize(
                    containerDetailData?.storage ?? containerDetailData?.size,
                  )}
                </h4>
              </div>
              <div>
                <p>Date</p>{" "}
                <h4>{convertDate(containerDetailData?.created_at)}</h4>
              </div>
              <div>
                <p>Last Updated </p>{" "}
                <h4>{convertDate(containerDetailData?.updated_at)}</h4>
              </div>
            </div>
          </div>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />

      <CommonModal
        className="share-container"
        visible={isShareModalVisible}
        title={accessType === "Share" ? "Public" : "Private"}
        BtnText={accessType === "public" ? "Share Publicly" : "Share Privately"}
        customButton
        icon={<ShareIcon />}
        onClick={handleShare}
        customBtutton={true}
        btnDisabled={isShareBtnDisable}
        content={
          <>
            <div className="share-container_top">
              <CustomSelect
                regularInput
                label
                required
                labelText="Choose Access Type"
                isOpen={false}
                value={accessType}
                defaultValue={accessType}
                options={[
                  { value: "public", label: "Public", disabled: false },
                  { value: "private", label: "Private", disabled: false },
                ]}
                onChange={(value) => handleChangeShare("accessType", value)}
              />
            </div>

            {accessType === "public" && (
              <>
                <label htmlFor="">Public Sharing</label>
                <div className="publicSec">
                  <div className="publicSec_left">
                    <Tooltip
                      title={`${env.frontendUrl}/file/${mediaToShare?.ipfs_url}`}
                    >
                      <p>
                        <a
                          target="blank"
                          href={`${env.frontendUrl}/file/${mediaToShare?.ipfs_url}`}
                        >
                          {env.frontendUrl}/file/{mediaToShare?.ipfs_url}
                        </a>
                      </p>
                    </Tooltip>{" "}
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        copy(
                          `${env.frontendUrl}/file/${mediaToShare?.ipfs_url}`,
                        );
                      }}
                    >
                      <CopyIcon />
                    </span>
                  </div>
                  <ButtonCustom regularBtn label="Share" />
                </div>
              </>
            )}

            {accessType === "private" && (
              <>
                <InputCustom
                  label
                  required
                  regularInput
                  minLength={2}
                  maxLength={1000}
                  placeholder="Enter Here"
                  error={wltErr}
                  value={walletAddress}
                  onChange={(e) =>
                    handleChangeShare("identifier", e?.target?.value)
                  }
                  labletext="Wallet Address or  Email"
                />

                <CustomSelect
                  regularInput
                  label
                  labelText="Choose Access Level"
                  required
                  defaultValue={accessLevel}
                  value={accessLevel}
                  onChange={(value) => handleChangeShare("accessLevel", value)}
                  description={
                    accessLevel === "read"
                      ? "The user can only read the files in the container."
                      : accessLevel === "manage"
                        ? //"Users can fully manage the container. They can edit, remove, and create files."
                          "Users can fully manage the contents of the container, including editing, deleting, and creating files within it."
                        : "The user can only add new files to the container, they cannot change or delete existing files."
                  }
                  options={[
                    {
                      value: "read",
                      label: "View Only",
                    },
                    {
                      value: "write",
                      label: "Write",
                    },
                    {
                      value: "manage",
                      label: "Manage",
                    },
                  ]}
                />
              </>
            )}
          </>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />

      <CommonModal
        className="confirmationRemoveModal"
        visible={isRemoveVisible}
        BtnText="Continue"
        icon={<DeleteIcon />}
        title="Delete Container"
        content={
          <div className="confirmationRemoveModal_inner">
            <h4>Are you sure you want to delete this container.</h4>
            <div className="confirmationRemoveModal_btns">
              <ButtonCustom
                onClick={() => setisRemoveVisible(false)}
                borderBtn
                className={isDeleteBtnDisable ? "disableBtn" : ""}
                disabled={isDeleteBtnDisable}
                label="Cancel"
              />

              <ButtonCustom
                onClick={deleteContainer}
                regularBtn
                className={isDeleteBtnDisable ? "disableBtn" : ""}
                disabled={isDeleteBtnDisable}
                label={
                  isDeleteBtnDisable ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                  ) : (
                    "Delete"
                  )
                }
              />
            </div>
          </div>
        }
        onOk={closeModal}
        onCancel={closeModal}
      />

      <CommonModal
        className="invitationModal"
        visible={invitationModalVisible}
        BtnText="Send Invitation"
        icon={<ShareIcon />}
        title="Invite Users to Container"
        content={
          <div className="invitationModal_inner">
            <h4>We couldn&apos;t associated with this email address:</h4>
            <div className="email-list">
              <p>
                <strong>{invetationEmail}</strong>
              </p>
            </div>
            <p>
              Please ensure the email address is correct or invite them using
              the form below.
            </p>

            <div className="invitationModal_btns">
              <ButtonCustom
                onClick={() => setInvitationModalVisible(false)}
                borderBtn
                label="Cancel"
              />
              <ButtonCustom
                regularBtn
                onClick={handleSendInvitations}
                label="Send Invitation"
              />
            </div>
          </div>
        }
        onOk={() => setInvitationModalVisible(false)}
        onCancel={() => setInvitationModalVisible(false)}
      />
    </div>
  );
}
FileMainTable.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  isFileGuide: PropTypes.bool,
  isFileGuideStore: PropTypes.bool,
};

export default FileMainTable;
