class Env {
  ipfsUrl;
  chainId;
  ethRpcUrl;
  chainName;
  backendUrl;
  hexChainId;
  captchaKey;
  frontendUrl;
  explorerUrl;
  ethereumUrl;
  ethContract;
  metaDataName;
  chainCurrency;
  defaultChainId;
  wltSignatureMsg;
  metaDataWebsiteUrl;
  metaDataDescription;
  websocketBackendUrl;
  tokenContractAddress;
  // metaMaskInbrowserUrl;
  walletConnectProjectId;
  metaDataIconWebsiteUrl;
  subscriptionContractAddress;

  constructor() {
    this.checkAndLoadEnv();
  }

  static getInstance() {
    if (!Env.instance) {
      Env.instance = new Env();
    }
    return Env.instance;
  }

  checkAndLoadEnv() {
    try {
      this.captchaKey = process.env.REACT_APP_CAPTCHA_KEY ?? "";
      this.explorerUrl = process.env.REACT_APP_EXPLORER_URL_ETH ?? "";
      this.frontendUrl = process.env.REACT_APP_FRONTEND_URL ?? "";
      this.ethRpcUrl = process.env.REACT_APP_ETH_RPC_URL ?? "";
      this.chainId = process.env.REACT_APP_CHAIN_ID_ETH ?? "1";
      this.backendUrl = process.env.REACT_APP_BACKEND_URL ?? "";
      this.hexChainId = process.env.REACT_APP_HEX_CHAIN_ID_ETH ?? "";
      this.metaDataName = process.env.REACT_APP_METADATA_NAME ?? "";
      this.chainName = process.env.REACT_APP_CHAIN_NAME_ETH ?? "Ethereum";
      this.chainCurrency = process.env.REACT_APP_CHAIN_CURRENCY_ETH ?? "ETH";
      this.wltSignatureMsg = process.env.REACT_APP_WALLET_SIGNATURE_MSG ?? "";
      this.defaultChainId = process.env.REACT_APP_DEFAULT_CHAIN_ID ?? "1";
      this.ethContract = process.env.REACT_APP_CONTRACT_ADDRESS_ETH ?? "";
      this.metaDataWebsiteUrl =
        process.env.REACT_APP_METADATA_WEBSITE_URL ?? "";
      this.metaDataDescription =
        process.env.REACT_APP_METADATA_DESCRIPTION ?? "";
      this.metaDataIconWebsiteUrl =
        process.env.REACT_APP_METADATA_ICON_WEBSITE_URL ?? "";
      this.walletConnectProjectId =
        process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? "";
      this.ipfsUrl = process.env.REACT_APP_IPFS_URL;
      this.tokenContractAddress = process.env.REACT_APP_TOKEN_CONTRACT;
      this.tokenContractAddressUsdt = process.env.REACT_APP_TOKEN_CONTRACT_USDT;
      this.subscriptionContractAddress =
        process.env.REACT_APP_SUBSCRIPTION_CONTRACT;
      this.websocketBackendUrl =
        process.env.REACT_APP_WEBSOCKET_BACKEND_URL ?? "";
    } catch (error) {
      console.error("error while load envs : ", error);
    }
  }
}

export const env = Env.getInstance();
