import { env } from "../constants/env";
import { BrowserProvider, ethers } from "ethers";
import ERC20abi from "../constants/abi/erc20.json";

class ERC20Contract {
  contract = null;
  decimals = 0;

  constructor() {}

  async init(walletProvider, contractAddress) {
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      this.contract = new ethers.Contract(contractAddress, ERC20abi, signer);
    } catch (error) {
      console.error("Failed to initialize contract:", error);
      throw error;
    }
  }

  async getBalance(address) {
    try {
      if (!this.contract)
        throw new Error("Contract instance is not initialized.");
      return await this.contract.balanceOf(address);
    } catch (error) {
      console.error("Failed to fetch balance:", error);
      throw error;
    }
  }

  async approve(address, amount = 0) {
    try {
      if (!this.contract)
        throw new Error("Contract instance is not initialized.");

      const decimals = (await this.getDecimals()) ?? 18;

      const roundedAmount = parseFloat(amount.toFixed(Number(decimals)));

      const adjustedAmount = ethers.parseUnits(
        roundedAmount.toString(),
        Number(decimals),
      );

      return await this.contract.approve(address, adjustedAmount);
    } catch (error) {
      console.error("Failed to approve transaction:", error);
      throw error;
    }
  }

  async getAllowance(owner) {
    try {
      if (!this.contract)
        throw new Error("Contract instance is not initialized.");

      return await this.contract.allowance(
        owner,
        env.subscriptionContractAddress,
      );
    } catch (error) {
      console.error("Failed to fetch allowance:", error);
      throw error;
    }
  }

  async getDecimals() {
    try {
      if (!this.contract)
        throw new Error("Contract instance is not initialized.");
      if (this.decimals) {
        return this.decimals;
      }
      this.decimals = await this.contract.decimals();
      return this.decimals;
    } catch (error) {
      console.error("Failed to fetch decimals:", error);
      throw error;
    }
  }
}

export default ERC20Contract;
