import React, { useState } from "react";
import { Upload } from "antd";
import "./DragDrop.scss";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import { DeleteOutlined } from "@ant-design/icons";
import CommonModal from "../CommonModal/CommonModal";
import ButtonCustom from "../Components/ButtonCustom/ButtonCustom";

const { Dragger } = Upload;

const DragDrop = ({ label, fileList, setFileList, loading }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pendingFile, setPendingFile] = useState(null); 

  const showModal = (file) => {
    setPendingFile(file); 
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (pendingFile) {
      setFileList([pendingFile]); 
    }
    setIsModalVisible(false);
    setPendingFile(null); 
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPendingFile(null); 
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload(file) {
      const maxFileSize = 10 * 1024 * 1024;

      if (file.size > maxFileSize) {
        toast.error("File size cannot exceed 10 MB.");
        return false;
      }

      if (fileList.length > 0) {
        showModal(file); // Show modal if a file already exists
        return false;
      } else {
        setFileList([file]); // Set the file if no file is present
      }
      return false; // Prevent auto-upload
    },
    onDrop(e) {
      console.log("Dropped files:", e.dataTransfer.files);
    },
  };

  const handleRemove = (file) => {
    setFileList((prevFiles) => prevFiles.filter((item) => item.uid !== file.uid));
  };

  return (
    <>
      <div className="dragDrop">
        <h3>{label}</h3>
        <Dragger {...props} disabled={loading}>
          <p className="ant-upload-text">
            <span>Choose File</span> or Drop here
          </p>
        </Dragger>
        {fileList.length > 0 && (
          <div className="file-list">
            <h4>Uploaded File:</h4>
            <ul>
              {fileList.map((file) => (
                <li key={file.uid} className="file-item">
                  <span>{file.name}</span>
                  <DeleteOutlined
                    className="remove-icon"
                    onClick={() => handleRemove(file)}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <CommonModal
        visible={isModalVisible}
        title="Confirmation"
        content={
          <div className="confirmationRemoveModal_inner">
            <h4>You have reached the upload limit. Do you want to replace the existing file?</h4>
            <div className="confirmationRemoveModal_btns">
            <ButtonCustom regularBtn label="No" onClick={handleCancel} />

              <ButtonCustom borderBtn label="Yes" onClick={handleOk} />
            </div>
          </div>
        }
        onOk={handleOk}
        onCancel={handleCancel}
        btnDisabled={false}
        className="custom-class confirmationRemoveModal"
      />
    </>
  );
};

DragDrop.propTypes = {
  label: PropTypes.string.isRequired,
  fileList: PropTypes.arrayOf(PropTypes.object).isRequired,
  setFileList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default DragDrop;
