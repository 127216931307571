import React from "react";
import DAO from "../Pages/DAO/DAO";
import { Navigate } from "react-router-dom";
import Welcome from "../Pages/Login/Welcome";
import { Path } from "./Constant/RoutePaths";
import Members from "../Pages/Members/Members.jsx";
import AccessKey from "../Pages/AccessKey/AccessKey";
import Settings from "../Pages/Settings/Settings.jsx";
import Dashboard from "../Pages/Dashboard/Dashboard";
import AccountType from "../Pages/AccountType/AccountType";
import ForgotPassword from "../Pages/Login/ForgotPassword";
import BusinessAccount from "../Pages/Login/BusinessAccount";
import PersonalAccount from "../Pages/Login/PersonalAccount";
import PublicShare from "../Pages/PublicShare/PublicShare.jsx";
import OtpVerification from "../Pages/Login/OtpVerification.jsx";
import Notification from "../Pages/Notification/Notification.jsx";
import FolderManage from "../Pages/FileManagement/FolderManage.jsx";
import FileManagement from "../Pages/FileManagement/FileManagement";
import SelectAccountScreen from "../Pages/Login/SelectAccountScreen";
import BillingInformation from "../Pages/BillingInformation/BillingInformation";
import AccountTypeDashboard from "../Pages/AccountType/AccountTypeDashboard.jsx";
import ApiKeyTable from "../Pages/ApiKey/ApiKeyTable.jsx";
import ContactUs from "../Pages/ContactUs/ContactUs.jsx";

export const PAGES = {
  PUBLIC_PAGES: [
    { path: Path.WELCOME, element: <Welcome /> },
    { path: Path.SELECT_ACCOUNT, element: <SelectAccountScreen /> },
    { path: Path.PERSONAL_ACCOUNT, element: <PersonalAccount /> },
    { path: Path.BUSINESS_ACCOUNT, element: <BusinessAccount /> },
    { path: Path.VERIFY_OTP, element: <OtpVerification /> },
    { path: Path.FORGOT_PASSWORD, element: <ForgotPassword /> },
    { path: Path.FILE, element: <PublicShare /> },

    { path: "*", element: <Navigate to={Path.WELCOME} /> },
  ],

  PRIVATE_PAGES: [
    { path: "/", element: <Navigate to={Path.DASHBOARD} /> },
    { path: Path.DASHBOARD, element: <Dashboard /> },
    { path: Path.FILE_MANAGEMENT, element: <FileManagement /> },
    { path: Path.FOLDER_MANAGE, element: <FolderManage /> },
    { path: Path.ACCESSKEY, element: <AccessKey /> },
    { path: Path.MEMBERS, element: <Members /> },
    { path: Path.DAO, element: <DAO /> },
    { path: Path.API_KEY, element: <ApiKeyTable /> },
    { path: Path.SETTINGS, element: <Settings /> },
    { path: Path.ACCOUNT_TYPE, element: <AccountType /> },
    { path: Path.PLAN, element: <AccountTypeDashboard /> },
    { path: Path.NOTIFICATION, element: <Notification /> },
    { path: Path.FILE, element: <PublicShare /> },
    { path: Path.BILLING_INFORMATION, element: <BillingInformation /> },

    { path: Path.CONTACTUS, element: <ContactUs /> },

    { path: "*", element: <Navigate to={Path.DASHBOARD} /> },
  ],
};
